(function() {
	'use strict';

	myApp
	.factory('getTrainingLibraryFactory', getTrainingLibraryFactory);

	function getTrainingLibraryFactory (
		$http,
		$q,
		$cookieStore,
		$rootScope,
		SharedDataSvc,
		localStorageService,
		authentication,
		ServiceAgent,
		PROXY_ACTION_URL
	){
		var service = {
			getData: getData,
			getPdfData: getPdfData,
			getNewDocuments: getNewDocuments,
			submitUserNameAndContinue:submitUserNameAndContinue,
			getPdfDataFromS3: getPdfDataFromS3
		};

		return service;

		function getData () {
			var deferred = $q.defer();
			var activeStoreId;

			if ($cookieStore.get('storeId')) {
				activeStoreId = $cookieStore.get('storeId');
			} 

			if (localStorageService.get('trainingLibraryDocs')) {
				deferred.resolve(localStorageService.get('trainingLibraryDocs'));
			} else {
				var headers = SharedDataSvc.commonHeadersJSON;
				headers.endTarget = 'rentacentervrto/rest/trainingLibrary/downloadIndex';
				headers.sessionToken = authentication.getCurrentSessionId();

				ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST',
					headers,
					null,
					false
				).then(
					function(response) {
						var documentsArray = response.documents;
						var isManned = SharedDataSvc.globalVars.storeSetup.isManned;
						// var isManned = false;
						// for (var i = documentsArray.length - 1; i >= 0; i--) {
						// 	console.log(documentsArray[i].manned);
						// }
						var cleaned = _.remove(documentsArray, function(obj) {
							if (isManned && obj.manned) {
								return obj;
							}
							else if(!isManned && obj.unmanned)
							{
								return obj;
							}
						});
						var sorted = _.sortBy(cleaned, ['isNew','publishDate']);
						void 0;
						$rootScope.trainingCount = getNewDocuments(cleaned);
						
						sorted.reverse();
						void 0;
						localStorageService.set('trainingLibraryDocs', sorted);
						deferred.resolve(sorted);
					}, 
					function (response) {
						//TODO: handle error here for downloading training library
						void 0;
						deferred.reject(response);
					}
				);
			}

			return deferred.promise;
		}

		function getNewDocuments (trainingLibraryDocs) {
			var counter = 0;
			if(trainingLibraryDocs)
			{

				for (var i = trainingLibraryDocs.length - 1; i >= 0; i--) {
					if(trainingLibraryDocs[i].isNew)
						counter++;
				};
			}
			return counter;
		}

		function getDocumentCount (documents) {
			return documents.length;
		}

		function submitUserNameAndContinue (username) {
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
				headers.endTarget = 'rentacentervrto/rest/trainingLibrary/downloadIndex';
				headers.sessionToken = authentication.getCurrentSessionId();
				headers.COMMENTS = username;
				
				ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST',
					headers,
					null,
					false
				).then(
					function (response) {
						void 0;			
						deferred.resolve(response);
					},
					function (response) {
						void 0;
						deferred.reject(response);
					}
				);
			return deferred.promise;
		}

		function getPdfData (contentUrl) {
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers['Access-Control-Allow-Headers'] = 'Encrypt-Mode, endTarget, sessionToken, RAC-KIOSK-LOCATION-ID, Content-Type, filename';
			headers['Access-Control-Allow-Origin'] = 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,filename,RAC-CORRELATION-ID';//AC 27054 code Changes
			headers['Accept'] = 'application/pdf';
			headers.endTarget = 'rentacentervrto/rest/trainingLibrary/download';
			// 
			headers.filename = contentUrl.split('?')[1];
			headers.sessionToken = authentication.getCurrentSessionId();

			ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST', 
					headers, 
					null, 
					false
			).then(
				// Success
				function(response) {
					// console.log(response);
					deferred.resolve(response);
				},
				// Error
				function(error) {
					deferred.reject(response);
				}
			);

			return deferred.promise;
		}
		
		//Feature 8407 - Move training library docs to S3 bucket
		function getPdfDataFromS3(contentUrl){
			var deferred = $q.defer();
			var headerData = SharedDataSvc.commonHeadersJSON;
			headerData['Content-type'] = 'application/pdf';
			
			$http({
	            url : contentUrl,
	            method : 'GET',
	            headers : headerData,
	            responseType : 'arraybuffer'
	        }).then(
				function(response) {
					deferred.resolve(response);
				},
				function(error) {
					deferred.reject(response);
				}
			);

			return deferred.promise;
			
		}

	}

})();
'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('mannedStore', ['SharedDataSvc', function (SharedDataSvc) { //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
  return {
    template: '<span>{{phone}}</span>',
    restrict: 'A',
    link: function(scope, element, attrs) {},
    controller: function ($scope, SharedDataSvc) { //AC-28614 Changes removed MANNED_STORE_PHONE and UNMANNED_STORE_PHONE
      $scope.manned = SharedDataSvc.globalVars.storeSetup.isManned;
      $scope.$watch(function () {
        return SharedDataSvc.globalVars.storeSetup.isManned;
      }, function (isManned) {
        void 0;
        $scope.phone = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber; //AC-28614 Changes 
      });
    }
  }
}]);

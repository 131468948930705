(function(){

	angular
			.module('myApp')
			.factory('productCategoriesFactory', productCategories);

	function productCategories(
			$http,
			$q,
			localStorageService,
			API_DOMAIN,
			ServiceAgent,
			SharedDataSvc,
			PROXY_ACTION_URL,
			authentication
	) {
		return {
			getData: getData
		}

		function getData () {
			void 0;
			var deferred = $q.defer();
			var headers = {
				'endTarget': 'site/json/productCategories.json',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey
			};
			ServiceAgent.httpCall(
					PROXY_ACTION_URL,
					'POST',
					headers,
					null,
					false
			).then(function (response) {
						void 0;
						deferred.resolve(response);
					},
					function (response) {
						void 0;
						deferred.reject(response);
					});
			return deferred.promise;
		}
	}
})();
'use strict';

myApp.filter('interpretInputType', function(){
	return function(input) {
        var inputType = input.toLowerCase();

        switch(input) {
            case 'quantity':
            case 'integer':
                inputType = 'number';
                break;
        }

        return inputType;
	}
});

myApp.filter('momentFormat', function(){
    return function(input, format) {
        if (!input || !input.length) { return; }
        return moment(input).format(format);
    }
});

myApp.filter('payments', function($filter) {
    return function(input) {
        if (isNaN(input)) {
            return input;
        }
        return $filter('currency')(input, "$ ", 2);
    }
});

myApp.filter('formatNextRenewDate', function($filter) {
    return function(input) {
        if (isNaN(input)) {
            return input;
        }

        var input = moment(input).format('MMM') +" "+ moment(input).format('D');
        return input;
    }
});

myApp.filter('formatInitialPaymentDate', function($filter) {
    return function(input) {
        if (isNaN(input)) {
            return input;
        }

        var input = moment(input).format('MMM') +" "+ moment(input).format('D') +", "+ moment(input).format('YYYY');
        return input;
    }
});

myApp.filter('telephone', function () {
    return function (tel) {
        if (!tel) { return ''; }

        var value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if (country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + " (" + city + ") " + number).trim();
    };
});

myApp.filter('dateSuffix', function($filter) {
  var suffixes = ["st", "nd", "rd", "th"];
  return function(input) {
    if(!input) {
        return '';
    }
    var day = input.substr(input.length-1, 1);
    switch (day)
    {
       case '1': 
        if(input == '11'){
            return input + 'th';
        } 
        else {
            return input + 'st';
        }
        break;
    
       case '2': 
        if(input == '12'){
            return input + 'th';
        } 
        else {
            return input + 'nd';
        }
        break;
    
       case '3': 
        if(input == '13'){
            return input + 'th';
        } 
        else {
            return input + 'rd';
        }
        break;
    
       default:  return input + 'th';
    }
  };
});

myApp.filter('decimalFilter', function($filter) {
  return function(input, param) {
    var val = parseFloat(Math.round(input)/100).toFixed(param);
    return val;
  };
});

myApp.filter('camelCase', function (){ // for one word at a time
    var camelCaseFilter = function (input){
         for ( var i = 0; i < input.length; i++ ) 
             input = input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
         return input;
     };
    return camelCaseFilter;
});
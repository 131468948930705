// application (entering)
myApp.factory('getSpecificApplication', function ($http, $q, SharedDataSvc, $cookieStore, $rootScope, API_DOMAIN, PROXY_ACTION_URL, authentication, ServiceAgent) {
	return {
		searchHT : function(mData){
			
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/customer/create';
			headers.sessionToken = authentication.getCurrentSessionId();
			headers.orderOriginator = 'AWP';
			
			var entryPointFromCookie = $cookieStore.get('AwpEntryPoint');
			if(typeof entryPointFromCookie !== "undefined"){
				mData.entryPointName = entryPointFromCookie;
				void 0;
			}
			
			void 0;
			
			// NEW CUSTOMERS
			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				mData,
				true
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });
			return deferred.promise;
		}
	};
});
'use strict';
angular.module('myApp')
.directive('emailAgreement', ['getInitialDashboard', 'modalService', 'customerDataFactory', function (getInitialDashboard, modalService, customerDataFactory) {
  return {
    template: '<a class="glyphicon glyphicon-envelope"></a>',
    restrict: 'A',
    scope: {
      customer: "=",
      sendObj:"&"
    },
    link: function (scope, element, attrs) {
      var eventName = attrs.eventName || 'click';
      element.on(eventName, function(){
        scope.activeAgreements = [];
        scope.activeAgreements = scope.customer.activeAgrList;
        scope.isHybrid = scope.$root.isHybrid;
        scope.showError = false;
        if(scope.$root.returnToScreen){
          scope.$root.returnToScreen = false;
          scope.customerInfo.email = scope.$root.editedEmail;
          scope.$root.editedEmail = undefined;
          var modalEmailAgreement = modalService.open({
            templateUrl: '/partials/modal_emailAgreement.html',
            scope: scope,
            backdrop: 'static'
          });
        } else {
          /* VAN-340 changes */
          //if(scope.isStoreManned && !scope.isHybrid){
            customerDataFactory.findByEngagementId(scope.customer.engagementId, false).then(function(response) {
                scope.customerInfo = response.customer;
                var modalEmailAgreement = modalService.open({
                  templateUrl: '/partials/modal_emailAgreement.html',
                  scope: scope,
                  backdrop: 'static'
                });
            }, function(error){
                void 0;
                return false;
            });
          /*} else {
              var modalEmailAgreement = modalService.open({
                templateUrl: '/partials/modal_emailAgreement.html',
                scope: scope,
                backdrop: 'static'
              });
          }  */  
          /* VAN-340 changes */      
        }
      });
    },
    controller: function ($scope, $element, $rootScope, $q, getInitialDashboard, modalService, SharedDataSvc, customerDataFactory, applicationService) {
      $scope.sendObj = {
        store: false,
        customer: true
      };
      $scope.isStoreManned = SharedDataSvc.globalVars.storeSetup.isManned;
      $scope.sendEmails = function () {
        var engagementId = [], emailAgreementList = "";
        angular.forEach($scope.activeAgreements, function(item){
          var el = angular.element("#"+item.agrNum);
          if(el[0].checked && engagementId.indexOf(item.engagementId) < 0){
            engagementId.push(item.engagementId);
          }
        });
    	  SharedDataSvc.globalVars.engagementId = $scope.customer.engagementId; //story 26289 code changes reviewed by Theenmathi
        emailAgreementList = engagementId.toString();
        if(emailAgreementList == ""){
           $scope.showError = true;
           return false;
        }
        getInitialDashboard.sendAgreements($scope.customer.customerId, emailAgreementList, $scope.sendObj.customer, $scope.sendObj.store, ($scope.customerInfo ? $scope.customerInfo.email : undefined));
        modalService.dismiss();
      };

      $scope.confirmAndEditEmail = function(){
        $scope.emailAddress = $scope.customerInfo.email; 
        var confirmAndEditEmailModal = modalService.open({
          title: "Email Verification",
          templateUrl: 'partials/modal_confirmAndEditEmail.html',
          scope: $scope,
          backdrop: 'static',
          controller: 'confirmAndEditEmailCtrl'
        });
        confirmAndEditEmailModal.result.then(function(email) {
          $rootScope.editedEmail = email;
          $rootScope.returnToScreen = true;
          $element.trigger('click');
        });    
      }; 
    }
  }
}]);
(function(){

	'use strict';

	angular
	.module('myApp')
	.controller('emailAgreementRemoteCtrl', emailAgreementRemoteCtrl);

	function emailAgreementRemoteCtrl($scope, $q) {
		$scope.validateEmail = validateEmail;
		$scope.editEmail = editEmail;
		$scope.resetEmail = resetEmail;
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.verifyEmail = true;

		$scope.resetEmail();
		$scope.editEmailModeOn = false;
		$scope.isVerified = true;

		function validateEmail(el) {
			$scope.isVerified = false;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			if($scope.editEmailModeOff && !$scope.emailAddress){
				$scope.invalidEmailAddress = true;
				$scope.verifyEmail = false;
				return false;
			} 
			else{
				if($scope.emailAddress){
					$scope.verifyEmail = true;
					$scope.isVerified = true;}
			}
		}

		function resetEmail() {	
			$scope.emailVerify = undefined;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			$scope.verifyEmail = true;
			$scope.isVerified = false;
			$scope.editEmailModeOff = false;
		}

		function editEmail(){
			$scope.editEmailModeOn = true;
			/*VAN 510 - Electronic consent moved to application Flow - Demo changes*/
			$scope.isVerified = true;
			$scope.editEmailModeOff = true;
		}
	}
})();


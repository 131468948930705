myApp.factory("base64", function() {
	var a = {
		alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
		lookup: null,
		ie: /MSIE /.test(navigator.userAgent),
		ieo: /MSIE [67]/.test(navigator.userAgent),
		encode: function(b) {
			var c, d, e, f = a.toUtf8(b),
				g = -1,
				h = f.length,
				i = [, , , ];
			if (a.ie) {
				for (var j = []; ++g < h;) c = f[g], d = f[++g], i[0] = c >> 2, i[1] = (3 & c) << 4 | d >> 4, isNaN(d) ? i[2] = i[3] = 64 : (e = f[++g], i[2] = (15 & d) << 2 | e >> 6, i[3] = isNaN(e) ? 64 : 63 & e), j.push(a.alphabet.charAt(i[0]), a.alphabet.charAt(i[1]), a.alphabet.charAt(i[2]), a.alphabet.charAt(i[3]));
				return j.join("")
			}
			for (var j = ""; ++g < h;) c = f[g], d = f[++g], i[0] = c >> 2, i[1] = (3 & c) << 4 | d >> 4, isNaN(d) ? i[2] = i[3] = 64 : (e = f[++g], i[2] = (15 & d) << 2 | e >> 6, i[3] = isNaN(e) ? 64 : 63 & e), j += a.alphabet[i[0]] + a.alphabet[i[1]] + a.alphabet[i[2]] + a.alphabet[i[3]];
			return j
		},
		decode: function(b) {
			if (b.length % 4) throw new Error("InvalidCharacterError: 'B64.decode' failed: The string to be decoded is not correctly encoded.");
			var c = a.fromUtf8(b),
				d = 0,
				e = c.length;
			if (a.ieo) {
				for (var f = []; e > d;) f.push(c[d] < 128 ? String.fromCharCode(c[d++]) : c[d] > 191 && c[d] < 224 ? String.fromCharCode((31 & c[d++]) << 6 | 63 & c[d++]) : String.fromCharCode((15 & c[d++]) << 12 | (63 & c[d++]) << 6 | 63 & c[d++]));
				return f.join("")
			}
			for (var f = ""; e > d;) f += String.fromCharCode(c[d] < 128 ? c[d++] : c[d] > 191 && c[d] < 224 ? (31 & c[d++]) << 6 | 63 & c[d++] : (15 & c[d++]) << 12 | (63 & c[d++]) << 6 | 63 & c[d++]);
			return f
		},
		toUtf8: function(a) {
			var b, c = -1,
				d = a.length,
				e = [];
			if (/^[\x00-\x7f]*$/.test(a))
				for (; ++c < d;) e.push(a.charCodeAt(c));
			else
				for (; ++c < d;) b = a.charCodeAt(c), 128 > b ? e.push(b) : 2048 > b ? e.push(b >> 6 | 192, 63 & b | 128) : e.push(b >> 12 | 224, b >> 6 & 63 | 128, 63 & b | 128);
			return e
		},
		fromUtf8: function(b) {
			var c, d = -1,
				e = [],
				f = [, , , ];
			if (!a.lookup) {
				for (c = a.alphabet.length, a.lookup = {}; ++d < c;) a.lookup[a.alphabet.charAt(d)] = d;
				d = -1
			}
			for (c = b.length; ++d < c && (f[0] = a.lookup[b.charAt(d)], f[1] = a.lookup[b.charAt(++d)], e.push(f[0] << 2 | f[1] >> 4), f[2] = a.lookup[b.charAt(++d)], 64 != f[2]) && (e.push((15 & f[1]) << 4 | f[2] >> 2), f[3] = a.lookup[b.charAt(++d)], 64 != f[3]);) e.push((3 & f[2]) << 6 | f[3]);
			return e
		}
	};
	return {
		decode: a.decode,
		encode: a.encode
	}
});
(function() {
	'use strict';

	/**
	 * Generic factory for creating modals, tracking the active one and dismissing any that are open
	 * Not useful for tracking individual instances, such as with a singleton.
	 */
	myApp
		.factory('modalService', modalService);

	/* @ngInject */
	function modalService($modal, $rootScope) {
		var allowEscapeKey = false;
		var modalLock = false;
		var activeModal,
			service = {
			open: open,
			dismiss: dismiss,
			processing: processingModal,
			error: errorModal,
			lockError: lockErrorModal,//AC13233 & AC12058 Code changes
			activeModal: activeModal,
			lock: lock,
			openNew: openNew//AC23434 code change
		};
		return service;

		////////////////

		function open(modalOptions) {
			if (modalLock) return;
			if (typeof activeModal !== 'undefined') {
				dismiss();
			}
			modalOptions.keyboard = allowEscapeKey;
			activeModal = $modal.open(modalOptions);
			return activeModal;
		}
		
		//AC23434 code changes start
		function openNew(modalOptions) {
			if (modalLock) return;
			modalOptions.keyboard = allowEscapeKey;
			var activeModal = $modal.open(modalOptions);
			return activeModal;
		}
		//AC23434 code changes end

		function dismiss() {
			if (modalLock) return;
			if (typeof activeModal === 'undefined') return;
			activeModal.dismiss();
			activeModal = undefined;
		}

		function processingModal(title) {
			$rootScope.modalTitle = title || 'Processing';
			open({
				windowClass: 'processing-modal',
				templateUrl: 'partials/modal_Processing.html',
				size: 'sm',
				backdrop: 'static'
			});
		};

		function errorModal (message) {
			$rootScope.modalMessage = message || 'An error has occured';
			open({
				templateUrl: 'partials/alert_serviceError.tpl.html',
				scope: $rootScope //AC 25967 changes
			});
		}
		
		//Start - AC13233 & AC12058 Code changes
		function lockErrorModal (message) {
			$rootScope.modalMessage = message || 'An error has occured';
			open({				
				templateUrl: 'partials/alert_serviceLockError.tpl.html',
				scope: angular.element($('.agreement')).scope(), //AC 14120 code changes
				backdrop: 'static'
			});
		}
		//End - AC13233 & AC12058 Code changes
		
		function lock (lockStatus) {
			modalLock = lockStatus;
		}
	}
})();

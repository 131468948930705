myApp
.directive('ccDate', function() {
   return {
     require: 'ngModel',
     link: function(scope, element, attrs, modelCtrl) {
          element.bind("keypress", function(event){
            var target = event.target.value;
            void 0;
            void 0;

            if(target.length === 1) {
              void 0;
              element.context.value = target + '/';
            }

            // if(target.length === 2 && event.keyCode === 8 || target.length === 3 && event.keyCode === 46) {
            //   console.log('REMOVE SLASH');
            //   element.context.value = target.replace('/', '');
            // }


          });
     }
   };
});
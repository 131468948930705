'use strict';

angular.module('app.application', []);
angular.module('app.dashboard', [
// 'mgcrea.ngStrap.datepicker'
]);
angular.module('app.order', []);
angular.module('app.estimate', []);
angular.module('app.login', []);
angular.module('app.agreements', []);
angular.module('app.epay', []);
angular.module('app.training', []);
angular.module('app.dashboardAgreement', []);
angular.module('app.missingInfo', []);

//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
angular.module('app.dirPagination',[]);
//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends

var proxyBaseURL;
/*VAN-258 - Migrate Session Manager to Docker -Starts*/
/*proxyBaseURL = sessionManagerURL + '/SessionManager'; */ 
proxyBaseURL = '/SessionManager';
/*VAN-258 - Migrate Session Manager to Docker -Ends*/

// dev: 'http://52.5.248.106' <<-- This is old dev url. New is 'http://54.208.184.207'
// qa: 'https://test-awp.acceptancenow.com'
// Declare app level module which depends on filters, and services
var myApp = angular.module('myApp', [//  'ngMockE2E'
'ngRoute',
//'myApp.services',
'ngResource', 'ngAnimate', 'ngSanitize', 'ngCookies', 'ngIdle', 'mgcrea.ngStrap.datepicker','pascalprecht.translate',
// 'mgcrea.ngStrap.modal',
//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
'ui.mask', 'ui.bootstrap', 'LocalStorageModule', 'app.login', 'app.dashboard', 'app.application', 'app.order', 'app.estimate', 'app.training', 'app.dashboardAgreement', 'app.missingInfo','app.dirPagination'])
//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
// DEV: 'rac-van-svcs-dd-dev.cloudhub.io'
// QA: 'vanesb.cloudhub.io'
// UAT: 'vanesb-uat.cloudhub.io'
.constant('API_DOMAIN', 'vanesb.cloudhub.io')
// used by route security
.constant('SECURED_ROUTES', {})

/**
 * URL for proxy actions.
 * Example: http://52.5.248.106/SessionManager/sm/proxyaction
 */.constant('PROXY_ACTION_URL', proxyBaseURL + '/sm/proxyaction')

/**
 * Creates an Okta session. Should return a user object from Okta
 * Example: http://52.5.248.106/SessionManager/sm/sessionproxy?partner=191316&storeid=9222
 * Headers required:
 * - oktaUsername (09042@acceptancenow.net)
 * - oktaPassword (12345Test)
 */
 .constant('PROXY_SESSION_URL', proxyBaseURL + '/sm/sessionproxy')
 .constant('loginRedirectPath', '/login')
 .constant('languageSettings', [
		{ id: 'en', label: 'English', countryFlag:'us'},
		{ id: 'es', label: 'Spanish', countryFlag:'pr'}
	])
 .constant("AgreementSigningStatus", {
 		"cancel": "Declined",
		"decline": "Declined",
		"exception": "Pending",
		"fax_pending": "Pending",
		"id_check_failed": "Pending",
		"session_timeout": " Pending ",
		"signing_complete": "Accepted",
		"ttl_expired": "Pending",
		/*PRB0042777 - handle viewing_complete signer status in AWP*/
		"viewing_complete": "Accepted"
 	})
 .config(['$routeProvider', 'loginRedirectPath', 'SECURED_ROUTES', 
 	function($routeProvider, loginRedirectPath, SECURED_ROUTES) {
		$routeProvider.whenAuthenticated = function(path, route) {
			$routeProvider.when(path, route);
			SECURED_ROUTES[path] = true;
			return $routeProvider;
		};
	}])
 .config(['$routeProvider', '$compileProvider', '$idleProvider', '$keepaliveProvider', 'loginRedirectPath', '$translateProvider', 
	function($routeProvider, $compileProvider, $idleProvider, $keepaliveProvider, loginRedirectPath, $translateProvider) {
		// Prevents angular issue when opening a new tab
		$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|data):/);
		$routeProvider.when('/', {
			redirectTo : loginRedirectPath
		}).when('/login', {
			templateUrl : 'partials/login.html',
			controller : 'Login',
			controllerAs : 'login',
			title : 'Login'
		}).whenAuthenticated('/dashboard', {
			templateUrl : 'partials/dashboard.html',
			controller : 'Dashboard',
			controllerAs : 'dashboard',
			title : 'Dashboard'
		}).whenAuthenticated('/training', {
			templateUrl : 'partials/training.html',
			controller : 'Training',
			controllerAs : 'training',
			title : 'Training'
		}).whenAuthenticated('/dashboardAgreement', {
			templateUrl : 'partials/dashboardAgreement.html',
			controller : 'DashboardAgreement',
			controllerAs : 'dashboardAgreement',
			title : 'DashboardAgreement'
		}).whenAuthenticated('/dashboard/:engagementId', {
			templateUrl : 'partials/dashboard.html',
			controller : 'Dashboard',
			controllerAs : 'dashboard',
			title : 'Dashboard'
		}).whenAuthenticated('/estimate', {
			templateUrl : 'partials/estimate.html',
			controller : 'Estimate',
			controllerAs : 'estimate',
			title : 'Estimate'
		}).whenAuthenticated('/estimate/:engagementId', {
			templateUrl : 'partials/estimate.html',
			controller : 'Estimate',
			controllerAs : 'estimate',
			title : 'Estimate'
		}).whenAuthenticated('/agreement', {
			templateUrl : 'partials/agreement.html',
			title : 'Agreements'
		}).whenAuthenticated('/agreement/:engagementId', {
			templateUrl : 'partials/agreement.html',
			controllerAs : 'vm',
			title : 'Agreements'
		}).whenAuthenticated('/digitalAgreement', {
			templateUrl : 'partials/digitalAgreement.html',
			controller : 'digitalAgreement',
			title : 'Agreements'
		}).whenAuthenticated('/digitalAgreement/:engagementId', {
			templateUrl : 'partials/digitalAgreement.html',
			controllerAs : 'vm',
			title : 'Agreements'
		}).whenAuthenticated('/application', {
			templateUrl : 'partials/application.html',
			controller : 'Application',
			title : 'Application'
		}).whenAuthenticated('/application/:engagementId', {
			templateUrl : 'partials/application.html',
			controller : 'Application',
			title : 'Application'
		}).whenAuthenticated('/getmissinginfo', {
			templateUrl : 'partials/getMissingInfo.html',
			controller : 'MissingInfo',
			controllerAs : 'missingInfo',
			title : 'Application Info'
		}).whenAuthenticated('/ordersummary', {
			templateUrl : 'partials/ordersummary.html',
			controllerAs : 'orderSummary',
			title : 'Order'
		}).whenAuthenticated('/ordersummary/:engagementId', {
			templateUrl : 'partials/ordersummary.html',
			controller : 'OrderSummary',
			controllerAs : 'orderSummary',
			title : 'Order'
		}).whenAuthenticated('/epay', {
			templateUrl : 'partials/epay.html'
		}).whenAuthenticated('/epay/:engagementId', {
			templateUrl : 'partials/epay.html',
			controllerAs : 'vm'
		}).otherwise({
			redirectTo : loginRedirectPath
		});

		/* The session timeout duration is set to 4 mins */
		$idleProvider.idleDuration(240);
		
		$idleProvider.warningDuration(10);
		$keepaliveProvider.interval(10);
	}])
 .config(function($datepickerProvider) {
			angular.extend($datepickerProvider.defaults, {
				dateFormat : 'MM/dd/yyyy',
				startWeek : 1,
				autoclose : true,
				minDate : 'today'
			});
	});

 myApp.config(['$translateProvider', 'languageSettings', function ($translateProvider, languageSettings) {
	  $translateProvider.translations(languageSettings[0].id, en); //en.js
	  $translateProvider.translations(languageSettings[1].id, es); //es.js
	  
	  // load default language 'en' on startup
	  $translateProvider.preferredLanguage(languageSettings[0].id);
}]);

myApp.run(function(authentication, $rootScope, $location, $route, $window, $translate, $idle, $timeout, modalService, languageSettings, SECURED_ROUTES, AgreementSigningStatus, loginRedirectPath, $cookieStore, ServiceAgent, getTrainingLibraryFactory, SharedDataSvc, paymentFactory) {
	$rootScope.associatePassPrompt = false;
	$rootScope.languageSettings = languageSettings;
	$rootScope.selectedLanguage = sessionStorage.getItem('selectedLanguage') || $rootScope.languageSettings[0].id;
	$translate.use($rootScope.selectedLanguage);

	$rootScope.setLanguageLabel = function(){
		var option = _.find($rootScope.languageSettings, {
			id: $rootScope.selectedLanguage
		});
		return option.label;
	};

	$rootScope.setLanguageClass = function(){
		var option = _.find($rootScope.languageSettings, {
			id: $rootScope.selectedLanguage
		});
		return option.countryFlag;
	};

	$rootScope.changeLocale = function(val){
		$rootScope.showLanguageSettings = $cookieStore.get('isSpanishStore') || false;
		sessionStorage.removeItem('selectedLanguage');
		$rootScope.selectedLanguage = val;
		sessionStorage.setItem('selectedLanguage', $rootScope.selectedLanguage);
		var currentURL = $route.current.$$route.originalPath;
		$translate.use(val);
		if(currentURL == "/training"){
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Starts*/
			//$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? SharedDataSvc.trainLibraryDocs.documents_PR : SharedDataSvc.trainLibraryDocs.documents;
			var documentList_Spa =[];
			var documentList_Eng =[];
			if($rootScope.state=="PR"){
				if(SharedDataSvc.trainLibraryDocs.documents_PR){
					for(var j=0;j<SharedDataSvc.trainLibraryDocs.documents_PR.length;j++){
						if(SharedDataSvc.trainLibraryDocs.documents_PR[j].language=="SPA"){
							documentList_Spa.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}else{
							documentList_Eng.push(SharedDataSvc.trainLibraryDocs.documents_PR[j]);
						}
					}
					$rootScope.documentList = $rootScope.selectedLanguage == 'es' ? documentList_Spa: documentList_Eng;
				}
			}
			else{
				$rootScope.documentList = SharedDataSvc.trainLibraryDocs.documents;
			}
			/*VAN 499 - PR: Training library videos and PDF's should be related to PR flow (Spanish) - Ends*/
		}
	};

	function authRequired(path) {
		return SECURED_ROUTES.hasOwnProperty(path);
	}

	$rootScope.getParameterByName = function(name, url) {
	    if (!url) url = window.location.href;
	    name = name.replace(/[\[\]]/g, "\\$&");
	    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
	        results = regex.exec(url);
	    if (!results) return null;
	    if (!results[2]) return '';
	    return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	function checkAuth(path) {
		void 0;
		if ($cookieStore.get('navigatingToDocusign')) {
			authentication.isAuthenticated = true;
			var event = $rootScope.getParameterByName('signer_status') ? $rootScope.getParameterByName('signer_status').toLowerCase() : '';
			var isCoCustomerSignerPending = $cookieStore.get("isCoCustomerSignerPending");
			var agreementSigningStatus = AgreementSigningStatus[event];
			
			$cookieStore.put('navigatingToDocusign', null);
			$cookieStore.put('docusignSignerStatus', event);
	
			if(event == 'cancel'){
				$location.url('dashboard');
				modalService.open({
					windowClass: 'smallModals',
					templateUrl: 'partials/modal_afterDocusignSigningFailure.html',
					backdrop: 'static'
				});
			} else if(event == 'decline'){
				$location.url('dashboard');
			} else if(event == 'signing_complete' || event == 'null' || event == '' || event == 'viewing_complete'){
				if(isCoCustomerSignerPending){
					var engagementId = $rootScope.getParameterByName('engagementId');
					$location.url('/digitalAgreement/' + engagementId);
				} else {
					$location.url('dashboard');
					var isHTCustomer = $cookieStore.get('isHTCustomer');
					if(isHTCustomer){
						$cookieStore.put("isHTCustomer", null);
						$rootScope.successMsgDocusign = $rootScope.selectedLanguage == 'es' ? es.modal_afterDocusignSigningSuccess_desc3 : en.modal_afterDocusignSigningSuccess_desc3;
						modalService.open({
							windowClass: '',
							templateUrl: 'partials/modal_afterDocusignSigningSuccess.html',
							//backdrop: 'static'
						});
					}
				}
			} else {
				var engagementId = $rootScope.getParameterByName('engagementId');
				$location.url('/digitalAgreement/' + engagementId);
			}

			/*if (window.parent.location.href.match(/tag=/)){
			    if (typeof (history.pushState) != "undefined") {
			        var obj = { Title: document.title, Url: window.parent.location.pathname };
			        history.pushState(obj, obj.Title, obj.Url);
			    } else {
			        window.parent.location = window.parent.location.pathname;
			    }
			}*/
			//PRB0043039 - trigger update signer service on Viewing complete event
			//if(event == 'signing_complete' || event == 'decline'){
			if(event == 'signing_complete' || event == 'decline' || event == 'viewing_complete'){
				var signerData = {
					"agreementSigningStatus": agreementSigningStatus,
					"customerNumber": $rootScope.getParameterByName('signer_id')
				};
				SharedDataSvc.updateSignerData = signerData;		
				$timeout(function(){
					paymentFactory.updateSignerStatus(signerData).then(function(response) {
						//$window.location.reload();
					}, function(error) {
						SharedDataSvc.reportRaygun(error);
						void 0;
					})
				}, 2000);
			}						
		} else {
			if (authRequired(path) && !authentication.isAuthenticated) {
				$location.url(loginRedirectPath);
			}
		}
	}

	function timeoutModal() {
		var timeoutModal = modalService.open({
			title : 'Page Timeout',
			templateUrl : 'partials/modal_continueSession.html',
			backdrop : 'static',
			size : 'sm',
			scope : $rootScope
		});
		modalService.lock(true);
	}

	var currentURL = $location.url();

	if (authentication.isLoggedIn()) {
		$location.url(currentURL);
	}

	$rootScope.$on('$routeChangeStart', function(event, current, previous) {
		void 0;
		modalService.dismiss();
		//var path = current.$$route.templateUrl.split('/');
		//current.$$route.templateUrl = path[0] + '/' + $rootScope.selectedLanguage + '/' + path[2];
		//console.log(current.$$route.templateUrl);
		checkAuth(current.$$route.originalPath);
		event.preventDefault();
		ServiceAgent.cancelRequests();
	});

	$rootScope.$on('$routeChangeSuccess', function(event, current, previous) {
		$rootScope.title = current.$$route.title || 'Customer';
		window.scrollTo(0, 0);
		if (authentication.isAuthenticated) {
			$idle.watch();
		}
		if ($cookieStore.get('auth_timeout')) {
			if (authRequired(current.$$route.originalPath)) {
				timeoutModal();
			} else {
				modalService.lock(false);
				modalService.dismiss();
			}
		}
		$rootScope.showLanguageSettings = $cookieStore.get('isSpanishStore') || false;
		$rootScope.selectedLanguage = sessionStorage.getItem('selectedLanguage') || $rootScope.languageSettings[0].id;
		$translate.use($rootScope.selectedLanguage);
		$rootScope.associatePassPrompt = $cookieStore.get('auth_timeout');
	});

	$rootScope.$on('$routeChangeError', function(e, next, prev, err) {
		checkAuth(next.$$route.originalPath);
	});

	$rootScope.$on('$idleStart', function() {
		void 0;
		if (!authentication.isAuthenticated || $rootScope.associatePassPrompt) {
			return;
		}
		var timeoutWarningModal = modalService.open({
			title : 'Page Timeout',
			templateUrl : 'partials/alert_idleWarning.tpl.html',
			backdrop : 'static',
			size : 'sm',
			scope : $rootScope
		});

		timeoutWarningModal.result.then(function() {
			// VAN-2945 reloading agreement page to prevent submitting of undefined agreement values
			var currentURL = $location.url();
			if ((currentURL.indexOf('digitalAgreement') > -1) || (currentURL.indexOf('agreement') > -1)) {
				//$route.reload();
				$window.location.reload(); // to reload the whole state
			}
		}, function() {
			void 0;
		});
	});

	$rootScope.$on('$idleTimeout', function() {
		void 0;
		if (!authentication.isAuthenticated || $rootScope.associatePassPrompt) {
			return;
		}
		$rootScope.associatePassFail = false;
		$rootScope.associatePassPrompt = true;
		$cookieStore.put('auth_timeout', true);
		timeoutModal();
	});

	$rootScope.logoutUser = function() {
		void 0;
		var logoutUserConfirmationModal = modalService.open({
			title : 'Log out',
			templateUrl : 'partials/confirm_logout.tpl.html',
			size : 'sm',
			backdrop : 'static',
			scope : $rootScope
		});
	};

	$rootScope.logoutUserConfirm = function() {
		void 0;
		authentication.logoutUser();
		modalService.dismiss();
		$rootScope.associatePassFail = false;
		$cookieStore.put('auth_timeout', null);
		$location.url(loginRedirectPath);
	};

	$rootScope.verifyEmployeePass = function(pass) {
		
		//var auth = window.atob($cookieStore.get('store_nick_name'));
		//Defect 24783 - VAN-WEB- In Unmanned stores,the application is not taking any login after session timesout ' - start
		//var auth = window.atob($cookieStore.get('PP'));
		var auth = SharedDataSvc.base64.decode($cookieStore.get('PP'));
		//Defect 24783 - VAN-WEB- In Unmanned stores,the application is not taking any login after session timesout ' - end
		auth = auth.toLowerCase();
		pass = pass.toLowerCase();
		
		void 0;
		void 0;
		
		var currentURL = $location.url();
		if (auth === pass) {
			$cookieStore.put('auth_timeout', null);
			$rootScope.associatePassPrompt = false;
			modalService.lock(false);
			modalService.dismiss();

			$rootScope.showLanguageSettings = $cookieStore.get('isSpanishStore') || false;
			$rootScope.selectedLanguage = sessionStorage.getItem('selectedLanguage') || $rootScope.languageSettings[0].id;
		
			// VAN-2945 reloading agreement page to prevent submitting of undefined agreement values

			//ACDefect 24566 - reloading new customer popup to prevent existing customers from submitting application with different SSN
			if ((currentURL.indexOf('digitalAgreement') > -1) || (currentURL.indexOf('agreement') > -1) || (currentURL.indexOf('application') > -1) ) {
				//$route.reload();		   // only reloads controller
				$window.location.reload(); // to reload the whole state
			}

		} else {
			void 0;
			$rootScope.associatePassFail = true;
		}
	};

	//AC 25967 changes starts
	$rootScope.checkForDashboardNavigation = function() {
		if($location.path() == '/application' && SharedDataSvc.globalVars.genericError){
			SharedDataSvc.globalVars.genericError = false;
			$location.path('dashboard');
		}
	};//AC 25967 changes ends

	$rootScope.loginTrainingLibrary = function() {
		var trainingModal = modalService.open({
			title : 'New Customer',
			templateUrl : 'partials/alert_enterNameToAccess.tpl.html',
			size : 'sm',
			backdrop : 'static',
			scope : $rootScope
		});
	};

	$rootScope.submitUserNameForTL = function(userName) {
		if (userName) {
			$rootScope.noUserName = false;
			getTrainingLibraryFactory.submitUserNameAndContinue(userName).then(function(response) {
				modalService.dismiss();
				SharedDataSvc.trainLibraryDocs = response;
				sessionStorage.setItem('trainLibraryDocs', JSON.stringify(response));
				$location.path('/training');
			}, function(error){
				SharedDataSvc.reportRaygun(error);
				void 0;
			});
		} else {
			void 0;
			$rootScope.noUserName = true;
		}
	};

	$rootScope.handleButtonClick = function(route) {
		var currentURL = $location.url();
		if (currentURL.indexOf("/application") > -1 || currentURL.indexOf("/ordersummary") > -1) {
			var saveAppModalForTraining = modalService.open({
				title : 'Save Changes',
				templateUrl : 'partials/confirm_saveAppChanges.tpl.html',
				size : 'sm',
				backdrop : 'static',
				scope : $rootScope
			});
			saveAppModalForTraining.result.then(function(action) {
				if (action) {
					$rootScope.$broadcast('saveAppData', route);
				} else {
					if (route.indexOf("training") > -1) {
						$rootScope.loginTrainingLibrary();
					} else {
						$location.path("/dashboardAgreement");
					}
				}
			});
		} else {
			if (route.indexOf("training") > -1) {
				$rootScope.loginTrainingLibrary();
			} else {
				$location.path("/dashboardAgreement");
			}

		}
	};

});
/*
 myApp.config(['$httpProvider', function($httpProvider) {
 $httpProvider.defaults.useXDomain = true;
 delete $httpProvider.defaults.headers.common['X-Requested-With'];

 $httpProvider.defaults.headers.common.Authorization = 'Basic dGVzdDp0ZXN0';
 }]);
 */

myApp.run(function($http) {
	//$http.defaults.useXDomain = true;
	delete $http.defaults.headers.common['X-Requested-With'];
	//$http.defaults.headers.common['Authorization'] = 'Basic dGVzdDp0ZXN0';
	//$http.defaults.headers.common['Content-Type'] = 'text/plain';
});


(function() {
	'use strict';

	angular.module('myApp').controller('customFooterController', customFooterController);

	function customFooterController($scope, $rootScope, $location, $timeout, getStoreInfoFactory, SharedDataSvc, applicationService, $cookieStore) {

		$scope.checkStore = function(){
			if($cookieStore.get('isHybridMode')){
				$rootScope.isHybridMode = $cookieStore.get('isHybridMode');
			}
			if($cookieStore.get('isMannedMode')){
      			$rootScope.isMannedMode = $cookieStore.get('isMannedMode');
      		}
		};
	}; 
})();
'use strict';
//Defect 28266 - VAN: References getting wiped off in HT - Added $rootScope
myApp.service('SharedDataSvc', ['$cookieStore','$rootScope', function ($cookieStore,$rootScope) {

    //this.orderSub = [];
    //this.orderSub.quoteItem = [];
	//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Starts
	this.dashboardAgreementItems="";
	// Feature 9467 - AWP-Services: Paginate Invoice Dashboard-Activity code changes -Starts
	this.order="";
	// Feature 9467 - AWP-Services: Paginate Invoice Dashboard-Activity code changes -Ends
	//Feature 9467 - AWP-Services: Paginate Invoice Dashboard - Ends
    this.globalmessage = '';
    this.globalmessageShow = false;
    this.addItemForm = [];
    this.sessionVars = [];
    this.sessionVars.customerEntity = {};
    this.hasNCmodalRun = false;
    this.hasECmodalRun = false;
    this.hasCCmodalRun = false;
    this.hasPAmodalRun = false;
    this.hasADmodalRun = false;
    this.hasBImodalRun = false;
    this.hasAAmodalRun = false;
    this.hasDAmodalRun = false;
    this.hasMImodalRun = false;
    this.customerInfo = {};
    this.customerPrivate = {};
    this.customerInfo.phones = [];
    this.customerInfo.incomeSources = [];
    this.customerInfo.personalReferences = [];
    this.modalOpen = false;
    this.globalVars = {};
    this.deliveryTempId = '';
    this.itemDeleteTempIdx = 0;
    this.itemEditTempIdx = 0;
    this.vanStoreId = $cookieStore.get('storeId');
    this.viewLoading = false;
    this.versionNo = angular.element('.version-number').html();
    this.raygunKey;
    this.editOrder = false;
    this.initialPaymentValue;
    this.quoteService;
    this.vccBarcodeGenerateAttempts = 0;
    this.validatePassPhraseAttempts = 0;
    this.validateMasterCodePassPhraseAttempts = 0;
    this.currentEngagementData;
    this.regex;
    this.initialPaymentAmount;
    this.eligiblePromos = [];
    //Feature 7872 Integration of Logz code changes starts
    this.logzApiKey;
    this.isLogzEnabled;
    this.racCorrelatioId;
    this.searchValue;
    //Feature 7872 Integration of Logz code changes ends

    this.commonHeaders = {
        'Access-Control-Allow-Headers': 'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName, orderOriginator,RAC-CORRELATION-ID',//AC 27054 code Changes
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type': 'text/plain'
    };
    this.commonHeadersJSON = {
        'Access-Control-Allow-Headers': 'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName, orderOriginator,RAC-CORRELATION-ID', //Story 26289 code changes
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type': 'text/plain',
        'Public-Key':'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB'
    };
    this.commonHeadersENCRYPT = {
        'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName,RAC-CORRELATION-ID',//AC 27054 code Changes
        'Authorization':'Basic dGVzdDp0ZXN0',
        'Public-Key':'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB',
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type':'application/json'
    };
    this.agreementHeadersENCRYPT = {
        'Access-Control-Allow-Headers':'OPERATION-MODE,POS-TYPE',
        'Access-Control-Allow-Origin': 'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,partnerName,RAC-CORRELATION-ID',//AC 27054 code Changes
        'Authorization':'Basic dGVzdDp0ZXN0',
        'Public-Key':'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB',
        'RAC-KIOSK-LOCATION-ID': this.vanStoreId,
        'Content-Type':'application/json'
    };
    void 0;

    this.headerAuth = {
        'Authorization': 'Basic dGVzdDp0ZXN0'
    };

    var headerPubKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr5dicHCGzSkuKvpEWGpg3lyZ8Rd1DqaMp+mtqeBCbamaHQvsYZo8eJNbI5oBLhWoU9efXR3lAvwJYivleBFO1/SWWSflIDQq1Fp/Z51lpTqILTaG1KMg+J3FvwcfrQ9at8Ck5EiVxTKDn3+4LUuMwngQ2+ximyKg59MS5xIFiQU4PL6uQfUE+aAhWV8Jo6RDfQnqN+h7V4uFqV6a3UHQNiq6hxAZD7y4MScG+VUTmQIwuSd/16jLoE2YL/1NYBn+Zbkx8MWdbzj6I6RIQ5kONDYFmIU2ZLOo61NKeK3XIsKNrwPMD67H7wg8dHZewzAikajwtRr70wySoMg2F3PWywIDAQAB';
	
    this.globalVars.order =
    {
        customerId: "",
        processingFee: 25,
        deliveryFee: 0,
        languagePreference: "ENGLISH",
        calculatedTax:0,
        orderSubtotal:0,
        initialPayment:0,
        monthlyPayment:0,
        deliveryDate: "",
        currentEngagementId: null,
        currentEngagement: {
            engagementId: null,
            orderContent: null,
            customerFirstName: "",
            customerLastName: "",
            customerId: 0,
            vanStoreId: null,
            modifiedDateStamp: "",
            orderStatus: "",
            deliveryStatus: "",
            agreementStatus: "",
            applicationStatus: "",
            approvalAmount: 0,
            salesStatus: "",
            estimatedDeliveryDate: null, // 2014-05-24 00:00
            paymentStatus: "",
            invoiceNum: "",
            salesAssociateID: ""//
        },
        currentEngagementOrder: {
            customerId: 0,
            dateStamp: "",
            modifiedDateStamp: "",
            engagementId: null,
            agreementHtId: null,
            vanStoreId: "",
            orderStatus: "",
            deliveryStatus: "",
            paymentStatus: "",
            salesStatus: "",
            agreementStatus: "",
            reconcilationStatus: null,
            orderContent: {
                extendedServiceAmount: 0.00,
                confirmed: 0,
                otherFee: 0,
                installationFee: 0,
                deliveryFee: 0,
                orderIdentifier: "",
                orderItems: [ /*
                    {
                    itemPrice: 0.00,
                    brand: "", //"Maytag",
                    itemDescription: "", //"Maytag Top Load Dryer Bisque Electric",
                    category: "", // "Top Load Dryer",
                    quantity: 1,
                    color: "", //"Bisque",
                    setItems: [],
                    attributes: [
                        {
                        customAttribute: "Type",
                        value: "" // "Electric"
                        }
                      ],
                    iconURL: "", // "TopLoadDryer_Gas",
                    model: "",
                    priceBuckets: [],
                    unitType: "",
                    subType: ""
                    }
                */]
            },
            applicationStatus: "",
            customerHtID: "", // HT2110000276
            customerHtStoreId: null,
            estimatedDeliveryDate: null,
            orderId: null,
            orderOriginator: "AWP"
        }
    };

    this.globalVars.estimateReturn = {

    };
	
	this.globalVars.disableOrderButtons = ["false"];
	
	this.globalVars.disableOrderButtonsOnSetAbsent = ["false"];

    this.globalVars.status = {
        agreement: {
            eaNoticeSeen: false,
            agreementBlock1: {},// agreement
            agreementBlock2: {},// benefits plus
            agreementBlock3: {},// ldw
            agreementBlock4: {},
            declineRadioResetGroup: "",
            signedId: "",
            currentAgreementToSign: "",
            signingAgreeDecline: {},
            agreeCheck: false,
            agreementStatus: "Not Started",
            agreementStatusId: 0,
            possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "Agreement Declined",
                cssClass: "process-problem",
                editable: false
              },
              {
                id:2,
                title: "Agreements Accepted",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Agreements Pending",
                cssClass: "process-yellow",
                editable: false
              }
            ]
        },
        epay:{
            username:"",
            password:"",
            passwordVerify: "",
            loggedIntoEpay: false,
            newOrExistingAcct: true, // true=new, false=existing
            securityQuestion:[
                {
                  name: "What street did you grow up on?",
                  value: "What street did you grow up on?"
                },
                {
                  name: "What is your mother's maiden name?",
                  value: "What is your mother's maiden name?"
                },
                {
                  name: "What was the name of your elementary / primary school?",
                  value: "What was the name of your elementary / primary school?"
                },
                {
                  name: "What is your first pet's name?",
                  value: "What is your first pet's name?"
                },
                {
                  name: "What is your father's middle name?",
                  value: "What is your father's middle name?"
                },
                {
                  name: "What are the last 5 digits of your driver's license number?",
                  value: "What are the last 5 digits of your driver's license number?"
                },
                {
                  name: "In what city did you meet your spouse/significant other?",
                  value: "In what city did you meet your spouse/significant other?"
                },
                {
                  name: "What is the street number of the house you grew up in?",
                  value: "What is the street number of the house you grew up in?"
                }
            ],
            securityAnswer:"",
            initialPayment:{
                creditCard:{
                    cc1: "",
                    cc2: "",
                    cc3: "",
                    cc4: "",
                    nameOnCard:"",
                    expDate:"",
                    securityCode:"",
                    billingAddress1: "",
                    billingAddress2: "",
                    billingCity: "",
                    billingState: "",
                    billingZipCode: "",
                    billingEmail: "",
                    billingPhone: ""
                }
            },
            autoPayment: {
                useSameAcctAsInitial: false,
                existingBankOrCredit: "existing", // 'existing', 'credit', 'other'
                routingNumber:"",
                accountNumber:"",
                accountType:"",
                existingAcctNumber:"1234",
                nameOnAccount:"",
                creditCard:{
                    cc1: "",
                    cc2: "",
                    cc3: "",
                    cc4: "",
                    nameOnCard:"",
                    expDate:"",
                    securityCode:"",
                    billingAddress1: "",
                    billingAddress2: "",
                    billingCity: "",
                    billingState: "",
                    billingZipCode: "",
                    billingEmail: "",
                    billingPhone: ""
                }
            },
            epayStatus: "Not Started",
            epayStatusId: 0,
            possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Completed",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Pending",
                cssClass: "process-yellow",
                editable: false
              },
              {
                id:4,
                title: "Declined",
                cssClass: "process-problem",
                editable: false
              }
            ]
        },
        order:{
          orderStatus: "Not Started",
          orderStatusId: 0,
          possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Pending Confirmation",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:3,
                title: "Confirmed",
                cssClass: "process-complete",
                editable: false
              }
            ]
        },
        delivery: {
          deliveryStatus: "Not Scheduled",
          deliveryStatusId: 0,
          possibleStatuses: [
              {
                id:0,
                title: "Not Scheduled",
                cssClass: "process-problem",
                editable: true
              },
              {
                id:1,
                title: "Scheduled for ",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Confirmed",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Pickup",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:4,
                title: "Pending",
                cssClass: "process-complete",
                editable: false
              }
            ]
        },
        application: {
          applicationStatus: "Not Started",
          applicationStatusId: 0,
          possibleStatuses: [
              {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
              },
              {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
              },
              {
                id:2,
                title: "Approved for ",
                cssClass: "process-complete",
                editable: false
              },
              {
                id:3,
                title: "Declined",
                cssClass: "process-problem",
                editable: false
              },
              {
                id:4,
                title: "Outside of Service Area",
                cssClass: "process-problem",
                editable: false
              },
              {
                id:5,
                title: "Manual Review",
                cssClass: "process-yellow",
                editable: false
              },
              {
                id:6,
                title: "Confirmed",
                cssClass: "process-complete",
                editable: false
              }
            ]
        },
        dashboard: {
          mainDashboardStatus: "",
          mainDashboardStatusId: 0
        },
        store: {
          storeStatus: "Not Started",
          storeStatusId: 0,
          possibleStatuses: [
            {
                id:0,
                title: "Not Started",
                cssClass: "process-standby",
                editable: true
            },
            {
                id:1,
                title: "In Progress",
                cssClass: "process-yellow",
                editable: true
            },
            {
                id:2,
                title: "Pending",
                cssClass: "process-standby",
                editable: true
            },
            {
                id:3,
                title: "Complete",
                cssClass: "process-complete",
                editable: true
            },
            {
                id:4,
                title: "Declined",
                cssClass: "process-problem",
                editable: false
            }
          ]
        }
    };

    this.globalVars.customer = {
        firstName:"",
        lastName:"",
        middleInitial: "",
        fullName:"",
        email:"",
        dob:{
            month:0,
            year:0,
            day:0
        },
        ssn:{
            ssn1:"",
            ssn2:"",
            ssn3:""
        }
    };

    this.globalVars.agreements = [
      {
          index: 0,
          id: "agreementBlock1",
          name: "Rental-Purchase Agreement",
          pdfLink: "",
          agreed: false,
          signed: false
      },
      {
          index: 1,
          id: "agreementBlock2",
          name: "Optional Liability Damage Waiver",
          pdfLink: "",
          agreed: false,
          signed: false
      },
      {
          index: 2,
          id: "agreementBlock3",
          name: "Benefits Plus Form",
          pdfLink: "",
          agreed: false,
          signed: false
      },
      {
          index: 3,
          id: "agreementBlock4",
          name: "Arbitration Agreement",
          pdfLink: "",
          agreed: false,
          signed: false
      }
    ];

    this.globalVars.dialogs = {
        confirm: [
          {
              id: 86,
              name: "Cancel Confirmation",
              title: "Are you sure you want to discard these changes?",
              leftBtnText: "Cancel",
              leftBtnClass: "btn-default",
              leftBtnFn: "SharedDataSvc.sessionVars.dialogs.cancel();",
              rightBtnText: "Confirm",
              rightBtnClass: "btn-primary",
              rightBtnFn: "SharedDataSvc.navigateTo('/#/dashboard');"
          },
          {
              id: 75,
              name: "Decline Contract Notification",
              title: "This order cannot be completed if this agreement is declined.",
              leftBtnText: "Cancel",
              leftBtnClass: "btn-default",
              leftBtnFn: "SharedDataSvc.sessionVars.dialogs.cancel();",
              rightBtnText: "Confirm",
              rightBtnClass: "btn-primary",
              rightBtnFn: ""
          }
        ],
        alert: [
          {
              id: 70,
              name: "Manual Review Notification",
              title: "Submitted for Manual Review",
              paragraph: "AcceptanceNow will contact the customer soon.  For support and questions call 1-866-317-2011.",
              btnText: "Ok",
              btnClass:"btn-primary",
              btnFn: "SharedDataSvc.navigateTo('/#/dashboard');"
          },
          {
              id: 66,
              name: "Zip Not Covered Notification",
              title: "This zip code is outside of the service area.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: ""
          },
          {
              id: 67,
              name: "Declined Notification",
              title: "Application Denied",
              paragraph: "An explanatory letter has been emailed to the customer.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: "SharedDataSvc.navigateTo('/#/dashboard');"
          },
          {
              id: 68,
              name: "Approved Notification",
              title: "Application Approved",
              paragraph: "Approved for $XXX worth of merchandise.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: "updateDashboardAppStatus();"
          },
          {
              id: 69,
              name: "Approved Notification - Order Exceeds Approval",
              title: "Application Approved",
              paragraph: "Approved for $XXX worth of merchandise.<br><br>Existing order exceeds approval amount.  Please edit the order.",
              btnText: "Ok",
              btnClass: "btn-primary",
              btnFn: "SharedDataSvc.navigateTo('/#/ordersummary');"
          }
        ],
        cancel: function(modal){
          // close modal

        }
    };

    this.globalVars.orderConfirmPut = {
        applicationStatus: "", // "Accepted",
        orderContent: { // gets base64 encoded prior to PUT
            extendedServiceAmount:0,
            confirmed:0,
            otherFee:0,
            installationFee:0,
            deliveryFee:0,
            orderIdentifier: "", // "17F93C1E-220A-4F7C-98B2-7E027AF9C1DF",
            orderItems:[]
        },
        engagementId:null,
        customerHtID:"",
        salesStatus:"Pending",
        paymentStatus:"Pending",
        customerId:0,
        deliveryStatus:"Pending",
        orderStatus:"Pending",
        agreementStatus:"Pending",
        vanStoreId:"",
        orderOriginator: "AWP",
        exitReason: null
    };

    this.globalVars.orderEstimatePut =
    {
       "password":"88138",
       "extendService":"45.00",
       "requestDte":"2015-04-05 18:30:30",
       "confirmed":0,
       "otherAmt":0,
       "employeeId":"168045",
       "username":"88138",
       "store":"09057",
       "quoteItem":[],
       "orderIdentifier":"374DA808-53A3-4FED-BBF0-67B2BCDC43A4",
       "employeeName":"VAN WEB PORTAL",
       "deliveryAmt":"90.00",
       "customer":{
          "title":"",
          "lastName":"Tester",
          "firstName":"Marvin",
          "contactPhone1":"999-9999999",
       },
       "address":{
          "address2":"",
          "state":"NC",
          "address1":"2112 Burke Meadows Road Apt F1106336",
          "zip":"27103",
          "city":"Winston-Salem",
          "county":""
       },
       "nextRenewDte":"2015-03-01 18:30:30",
       "sessionId":"1112081",
       "vndSalesPerson":""
    };

    this.globalVars.newApplicationPut = {
      sessionId: "", // "123456",
      salesResourceId: "", // "000001",
      customerId:1, // 1
      approvalFlowType: "", // "TELECHECK_ONLY",
      bankingInfo: {
          routingNumber: "", // "12345678",
          accountNumber: "", // "010987654321"
      },
      idInfo:{
          idIssuingState: "", // "GA",
          idNumber: "", // "308494386",
          idSSN: "", // "666425600",
          idDOB: "", // "12251975",
          idLastName: "", // "SMITH",
          idFirstName: "", // "JOHN",
          idMiddleName: "", // "K",
          idPhone: "", // "2024324321",
          idAddressNumber: "", // "123",
          idAddressStreetName: "", // "FAKE",
          idAddressStreetType: "", // "ST",
          idAddressApt: "",
          idCity: "", // "PLANO",
          idState: "", // "TX",
          idZip: "" // "75010"
      },
      employmentInfo: {
          employer: "Deloitte", // "Deloitte",
          employerAddress: "",
          employerCity: "",
          employerState: "",
          employerZip: "",
          employerDept: "",
          monthlySalary: ""
      },
      referenceInfo:[
          {
            firstName: "John",
            lastName: "Smith",
            phone: "513-123-5432",
            relationship: "PARENT"
          },
          {
            firstName: "John",
            lastName: "Smith2",
            phone: "513-123-5432",
            relationship: "COUSIN"
          },
          {
            firstName: "John",
            lastName: "Smith3",
            phone: "513-123-5432",
            relationship: "COWORK"
          }
      ]
    };

    this.globalVars.agreementOrder = {
       "password":"88138",
       //"extendService":"45.00",
       "requestDte":"2015-04-05 18:30:30",
       "confirmed":0,
       "otherAmt":0,
       "employeeId":"168045",
       "username":"88138",
       "store": null,
       "quoteItem":[],
       "orderIdentifier": null,
       "employeeName": null,
       //"deliveryAmt": null,
       "customer":{
          "lastName": null,
          "firstName": null,
          "contactPhone1": null,
       },
       "nextRenewDte":"2015-03-01 18:30:30",
       "sessionId":"1112081",
       "vndSalesPerson":"AWP"
    };

    this.globalVars.agreementPrePut = {
        "customer": {
            "id": 3667,
            "engagementId": "3861",
            "languagePreference": "ENGLISH"
        },
        "initialPaymentDate": "20150301",
        "deliveryFee": 2000,
        "emailUnsignedAgreements": true,
        "selectedPromoOptions":null,// VAN-672 - Low Down Initial Payment should be displayed on RA
        "inventoryList":[
          // {
          //   "racBrand": "ACP", // 'ESV' for extended
          //   "itemModelNumber": "", // 'ExtSvc' for extended
          //   "racModelNumber": "PMA600",
          //   "depreciableLifeInMonths": 24,
          //   "itemOptions" : "",
          //   "itemOriginalCost": 13587
          // },
          // {
          //   "racBrand": "ESV",
          //   "itemModelNumber": "ExtSvc",
          //   "racModelNumber": "CNS-SRV-1",
          //   "depreciableLifeInMonths": 24,
          //   "racRateCode": "WAR396",
          //   "itemOriginalCost": 0  // Update this if there is an extended warranty
          // }
        ]
    };

    this.globalVars.dashboardListCache = [];

    // this becomes the object 'customerApp'
    this.globalVars.ApplicationSave = { // APPROVAL/APPLICATION PUT/SAVE
      sessionId: "", // "123456",
      salesResourceId: "", // "000001",
      customerId: 0, // 1
      engagementId:"",
      approvalFlowType: "", // "MANNED",
      bankingInfo:{
          routingNumber: "", // "12345678",
          accountNumber: "" // "010987654321"
      },
      idInfo:{
          idIssuingState: "", // "CO",
          idNumber: "", // "21111111",
          idSSN: "", // "123121234",
          idDOB: "", // "12251975",
          idLastName: "", // "SMITH",
          idFirstName: "",// "JOHN",
          idMiddleName: "", // "K",
          idPhone: "", // "2024324321",
          idAddress1: "", // "123 FAKE ST",
          idAddress2: "", // "",
          idCity: "", // "PLANO",
          idState: "", // "TX",
          idZip:  "" //"75010"
      },
      employmentInfo:{
          employer: "Deloitte", // "Deloitte",
          employerAddress:"",
          employerCity:"",
          employerState:"",
          employerZip:"",
          employerDept:"",
          monthlySalary:""
      },
      referenceInfo:[
          {
            firstName: "", //"John",
            lastName: "", //"Smith",
            phone: "", //"513-123-5432",
            relationship: "" //"PARENT"
          },
          {
            firstName: "", //"John",
            lastName: "", //"Smith2",
            phone: "", //"513-123-5432",
            relationship: "" // "COUSIN"
          },
          {
            firstName: "", //"John",
            lastName: "", //"Smith3",
            phone: "", //"513-123-5432",
            relationship: "" //"COWORK"
          }
      ]
    };

    this.globalVars.engagementPOST = {  // for NEW customers SHORT information ONLY!!!!
        customerId: 0,
        languagePreference: "ENGLISH",
        engagementId: null,
        orderContent: null,
        customerFirstName: "",
        customerLastName: "",
        vanStoreId: null,
        modifiedDateStamp: "", // "2014-05-12 00:00"
        orderStatus: "",
        deliveryStatus: "",
        agreementStatus: "",
        applicationStatus: "",
        estimatedDeliveryDate: null, // 2014-05-24 00:00
        paymentStatus: "", //
        salesStatus: "",
        reconcilationStatus: null,
        customerHtID: "", // HT2110000276
        customerHtStoreId: null,
        orderId: null,
        orderOriginator: "AWP",
        exitReason: null
    };


    // this comes back after you:  GET /rentacentervrto/rest/storeInfo
    this.globalVars.storeSetup = {
        htStoreName: "", // S FREEWAY 1240
        // brand: "HHGREGG",
        htStoreNumber: "", // 003
        vanStoreId: "",// 6841
        latitude: 0, // 40.33
        longitude: 0, // -103.2352
        serverIconPath: "", // /site/png/Ashley.png
        canScanId: false,
        isManned: false,
        taxRate: 6.5,
        processingFee: 2500,
        excludedBrands: [], // ["GE", "LG"],
        excludedCategories: [], // ["Appliances"],
        allowExtendedWarranty: true,
        allowedRentalRadiusMiles: 3000,
        serverPublicRSAKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwiCeRISLA97us3bmyQZVlEzK2TcETSc5r7NWIZ/xaCp0bi7SKPh1p5Xmtjpnt6vF+mOzExl8Hk1Y0mbqplmSvUhetVwbfE2+LSK3sKEFQsc3PwKyiugtaiCVkURZPKhUwNnQmZof6m7bB7BXykqGxEFqHoF+F7D11Q8CHicUDfmPwGCXaBKlTp8HBOJuhfB9a6r8hBC1OHbpsInnQzEODBTYUIetFv/1fv4tqyTD1su+VZa1hQsCNxeECll921dtktzJDcGunYFVupl2Mpjj5BQ/zdOrDVQyGiUmHDTzm89pm7cUegsWxt0F3au7WXIRvRA4oThlZ5b7I4tJB8QIYwIDAQAB"//
    };

    this.globalVars.paymentCalcSetup = {
       "customerEngagementId": null,
       "paymentToDate": "20150301", // initial payment date
       "id": null, // ht store id
       "customerId": null,
       /*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Starts*/
       /*"clubSwitch":this.clubStatus,
       "waiverSwitch":this.waiverStatus,*/
       /*VAN-802 - VAN - If BP not selected show $49, if BP select do not show - Ends*/
       "hoCustId": null, // customer ht id
       "agreementNumber": null
    };
    
    /* Removed following data of pdfSign from globalVars.paymentCalcSetup object to separate out pdfSigner and calcPayment call 
           "pdfSignatures": {
          "arbitrationSignaturePNG": null,
          "ldwSignaturePNG": null,
          "benefitsPlusPayFrequency": "MONTHLY",
          "benefitsPlusSignature": null,
          "rentalAgreementSignaturePNG": null,
          "benefitsPlusSignaturePNG": null,
          "ldwSignature": null,
          "rentalAgreementSignature": null,
          "arbitrationSignature": null,
          "language": "english"
       }
     */

	this.globalVars.signatureTemplate = {
		"customerId":null,
        "engagementId":null,
        "language": "english",
		"signatures": []
	};

    this.globalVars.navigateToDashboard = false;

    this.cashInitialPayment = {
      "empin": null, // 1515
      "id": null, // 2647
      "hoCustId": null,
      "paymentToDate": null,
      "agreementNumber": null,
      "clubSwitch": "Y",
      "amtOwed": null,
      "amtTendered": null,
      "changeAmt": null,
      "customerEngagementId": null,
      "customerLogin": null,
      "lastFourDigit": null,
      "autoPayAgreementInfo": [{
          "agreementNumber": null,
          "agreementType": null,
          "monthlyAmount": null
      }]
      };

    this.creditInitialPayment = {
       "agreement":{
          "id": null,
          "customer":{
             "lastName": null,
             "firstName": null,
             "login": null,
             "email": null,
             "phoneNumber": null
          },
          "engagement":{
             "id":31385,
             "hoCustName": null
          }
       },
       "payment":{
          "billingCity": null,
          "billingZipCode": null,
          "cvv": null,
          "creditCardNumber": null,
          "billingLastName": null,
          "expirationDate": null,
          "billingState": null,
          "billingAddressLine": null,
          "paymentAmountInCents": null,
          "billingFirstName": null
       },
       "paymentType": null
    };

    this.bankPaymentInitialPayment = {
       "agreement":{
          "id": null,
          "customer":{
             "lastName": null,
             "firstName": null,
             "login": null,
             "email": null,
             "phoneNumber": null
          },
          "engagement":{
             "id":31385,
             "hoCustName": null
          }
       },
       "payment":{
          "billingCity": null,
          "billingZipCode": null,
          "cvv": null,
          "creditCardNumber": null,
          "billingLastName": null,
          "expirationDate": null,
          "billingState": null,
          "billingAddressLine": null,
          "paymentAmountInCents": null,
          "billingFirstName": null
       },
       "bankingInfo":{
           "bankRoutingNumber": null,
           "bankAccountNumber": null,
           "bankAccountType": null
       },
       "paymentType": null
    };

    this.autoPaymentBenefits = {
        "SendFulfillment": 3,
        "ClientMemberId": "06418001334",
        "EmailAddress": "mahashel@gmail.com",
        "FirstName": "Irv",
        "HomePhone": "9702146363",
        "Gender": "M",
        "ProgramId": "10239",
        "Birthdate": "01\/02\/1960",
        "EffectiveDate": "12-08-2014",
        "LastName": "Machesky",
        "ShippingAddress": {
            "PostalCode": "37743",
            "City": "Greeneville",
            "State": "TN",
            "StreetAddress1": "1445 Allens Bridge"
        },
        "ActionCode":"A"
    };

    this.autoPaymentData = {
        "customerLogin": null,
        "customerPassword": null,
        "customerFirstName": null,
        "customerLastName": null,
        "engagementId": null,
        "emailAddress": null,
        "customerAddress": null,
        "customerCity": null,
        "customerState": null,
        "customerZip": null,
        "customerPhone": null,
        "paymentType": null,
        "creditCardInfo": {
            "creditCardNumber": null,
            "expirationMonth": null,
            "expirationYear": null,
            "cvvCode": null
        },
        "bankingInfo": {
            // "bankRoutingNumber": null,
            // "bankAccountNumber": null,
            // "bankAccountType": null // Checking, Savings or Business
        },
        "agreementList": [{
            "agreementNumber": null,
            "agreementType": null,
            "monthlyAmount": null
        }],
        "payRequest":{
        	"promoCode": null,
        	"promoDesc": null,
        	"promoAmount": null,
        	"promoAmtUsed": null,
        	"totalDue": null,
        	"passPhrase": null,
        	"hoCustName": null,
            "billingAddressLine": null,
            "billingCity": null,
            "billingState": null,
            "billingZipCode": null,
            'paymentAmountInCents': null
        }
    };

    this.customerData = {};
    this.agreementData = {};
    this.manualOverrideData = {
        engagementId: null,
        state: undefined
    };
    this.activeModal = undefined;
    this.manualOverrideMessage = "";
    this.invoiceDeliveryData = {
        deliveryDate: null,
        pickupDate: null,
        date: null,
        associateName: null,
        invoiceNumber: null
    };
    this.quoteCalculator = {
        date: null,
        selectedDate: null
    };
    this.deliveryData = {
      deliveryStatus: null,
      salesAssociateID: null,
      invoiceNum: null,
      estimatedDeliveryDate: null,
      applicationStatus: null,
      customerId: null,
      orderStatus: null,
      salesStatus: null,
      paymentStatus: null,
      agreementStatus: null,
      vanStoreId: null,
      customerHtID: null,
      engagementId: null,
      orderContent: null
    };

    this.generatedkeys = [];
    this.cleankeys = [];
    
    this.missingInfoData = { };
    
    this.isBlankOrNull = function(value){
			if(value == null || value == ""){
				return true;
			}else{
				return false;
			}
	};
	var isBlankOrNull = this.isBlankOrNull;
	
	this.convertZerosToBlank = function(customerData){
		void 0;
        
        if(customerData.phones[0].areaCode == "000"){customerData.phones[0].areaCode = "";}
		if(customerData.phones[1].areaCode == "000"){customerData.phones[1].areaCode =  "";}
		if(customerData.personalReferences[0].phone.areaCode == "000"){customerData.personalReferences[0].phone.areaCode =  "";}
		if(customerData.personalReferences[1].phone.areaCode == "000"){customerData.personalReferences[1].phone.areaCode =  "";}
		if(customerData.personalReferences[2].phone.areaCode == "000"){customerData.personalReferences[2].phone.areaCode =  "";}
		
		if(customerData.phones[0].number == "0000000"){customerData.phones[0].number =  ""; }
		if(customerData.phones[1].number == "0000000"){customerData.phones[1].number =  ""; }
		if(customerData.personalReferences[0].phone.number == "0000000"){customerData.personalReferences[0].phone.number =  ""; }
		if(customerData.personalReferences[1].phone.number == "0000000"){customerData.personalReferences[1].phone.number =  ""; }
		if(customerData.personalReferences[2].phone.number == "0000000"){customerData.personalReferences[2].phone.number =  ""; }
		
        if($rootScope.isReferenceRequired && $rootScope.state == 'PR'){
            if(customerData.personalReferences[3] && customerData.personalReferences[3].phone && customerData.personalReferences[3].phone.areaCode == "000"){
                customerData.personalReferences[3].phone.areaCode =  "";
            }
            if(customerData.personalReferences[3] && customerData.personalReferences[3].phone && customerData.personalReferences[3].phone.number == "0000000"){
                customerData.personalReferences[3].phone.number =  ""; 
            }
        }

		return customerData;
	};
	var convertZerosToBlank = this.convertZerosToBlank;
	

	this.checkMissingInfo = function(customerData, callback ){
		void 0;
		var isReferenceRequired = this.globalVars.storeSetup.isReferenceRequired;

		/* We are checking for missing info only if customer is approved 
		if(customerData.VanEngagement.applicationStatus.toLocaleLowerCase() !== "accepted"){
			return callback(true);
		}*/
		
		/* We will get overallApprovalStatus key only when approval engine is run for customer(Not a save draft case).
		 * If approval engine is not run we are not checking missing info for it.
		 * If approval engine is run and overallApprovalStatus is equal to accept then only go for missing info check.
		 */

		//VAN-438 - US stores navigate to missinginfo and PR stores never navigate to missing info
		if ( $rootScope.state == 'PR' && customerData.VanEngagement && customerData.VanEngagement.customer.customerOriginator && customerData.VanEngagement.customer.customerOriginator != "SLI" && customerData.VanEngagement.agreementOriginator != 'HighTouch'){//VAN-2401-PR stores to use SLI- AWP re-engagement
			 return callback(true);
		}
		
		 // For HT approved customer with agreement,by pass missing flow 
        /*if(customerData.agreement !== undefined && customerData.agreement.agreementStatus !== undefined && customerData.agreement.agreementStatus === 'PENDING' && (customerData.VanEngagement.agreementOriginator && customerData.VanEngagement.agreementOriginator.toLocaleLowerCase() === 'hightouch')) {
            return callback(true);
        }*/		

		//Defect 28266 - VAN: References getting wiped off in HT - Starts
		if (!$rootScope.orderLink && ($rootScope.startAppFlow || $rootScope.createOrderFlow) && isReferenceRequired) {
    		if(customerData.VanEngagement && customerData.VanEngagement.customer.customerOriginator && customerData.VanEngagement.applicationStatus && 
    				(customerData.VanEngagement.customer.customerOriginator == "SLI" || customerData.VanEngagement.customer.customerOriginator == "HighTouch") // VAN-813 New originator for HighTouch for HT originated approvals
    				&& customerData.VanEngagement.orderStatus == "NotStarted" && customerData.VanEngagement.applicationStatus == "Accepted")
    		{
    		  $rootScope.VANEngagementId = customerData.VANEngagementId;
    		  return callback(false);
    		} 
		}
		//Defect 28266 - VAN: References getting wiped off in HT - Ends
		
		if(customerData.overallApprovalStatus){	
			if(customerData.overallApprovalStatus != "ACCEPT"){
				void 0;
				return callback(true);	
			}	
		}else{
			void 0;
			return callback(true);
		}
		
		
		/* We are converting zeros to blank for phone no fields below
		 * because HT will return "000" and "0000000" when this info is blank. 
		 * And here we are checking afterwards only blank or null.(Zeros should not be treated as info found)
		 * */
		customerData = convertZerosToBlank(customerData);
		
		// Test code customerData.personalReferences[1].firstName = "";
		void 0;
		if( isBlankOrNull(customerData.firstName) || isBlankOrNull(customerData.lastName)
			|| isBlankOrNull(customerData.address[0].addrLine1) 
			|| isBlankOrNull(customerData.address[0].city)
			|| isBlankOrNull(customerData.address[0].state)
			|| isBlankOrNull(customerData.address[0].zipCode)
			|| isBlankOrNull(customerData.address[0].state)
			|| isBlankOrNull(customerData.address[0].state)
			|| isBlankOrNull(customerData.social)
			|| isBlankOrNull(customerData.driversLicense.birthDate)
			|| isBlankOrNull(customerData.driversLicense.id)
			|| isBlankOrNull(customerData.phones[0].areaCode)
			|| isBlankOrNull(customerData.phones[0].number)
			|| isBlankOrNull(customerData.phones[0].phoneType)
			|| isBlankOrNull(customerData.email)
			
			|| isBlankOrNull(customerData.incomeSources[0].incomeType)
            || ((customerData.driversLicense.idType == '1' || customerData.driversLicense.idType == '2') && isBlankOrNull(customerData.driversLicense.issuer))
            || ((customerData.incomeSources[0].incomeType == 'EMPLOYED') && ((isBlankOrNull(customerData.incomeSources[0].sourceName) || customerData.incomeSources[0].sourceName == '0') || (isBlankOrNull(customerData.incomeSources[0].sourcePhone.number) || customerData.incomeSources[0].sourcePhone.number == '0000000') || isBlankOrNull(customerData.incomeSources[0].monthlyIncome) || isBlankOrNull(customerData.incomeSources[0].sourceStartDate)))
            || ((customerData.incomeSources[0].incomeType == 'SELFEMPLOYED') && ((isBlankOrNull(customerData.incomeSources[0].sourceName) || customerData.incomeSources[0].sourceName == '0') || isBlankOrNull(customerData.incomeSources[0].monthlyIncome)))
		){
			callback(false);	
		} else if(isReferenceRequired !== false && (this.globalVars.storeSetup.state == 'PR') 
            && (isBlankOrNull(customerData.personalReferences[0].firstName)
            || isBlankOrNull(customerData.personalReferences[0].lastName)
            || isBlankOrNull(customerData.personalReferences[0].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[0].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[0].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[1].firstName)
            || isBlankOrNull(customerData.personalReferences[1].lastName)
            || isBlankOrNull(customerData.personalReferences[1].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[1].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[1].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[2].firstName)
            || isBlankOrNull(customerData.personalReferences[2].lastName)
            || isBlankOrNull(customerData.personalReferences[2].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[2].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[2].phone.number)

            || isBlankOrNull(customerData.personalReferences[3].firstName)
            || isBlankOrNull(customerData.personalReferences[3].lastName)
            || isBlankOrNull(customerData.personalReferences[3].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[3].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[3].phone.number))) {

            callback(false);
        } else if((isReferenceRequired !== false) && (isBlankOrNull(customerData.personalReferences[0].firstName)
            || isBlankOrNull(customerData.personalReferences[0].lastName)
            || isBlankOrNull(customerData.personalReferences[0].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[0].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[0].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[1].firstName)
            || isBlankOrNull(customerData.personalReferences[1].lastName)
            || isBlankOrNull(customerData.personalReferences[1].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[1].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[1].phone.number)
            
            || isBlankOrNull(customerData.personalReferences[2].firstName)
            || isBlankOrNull(customerData.personalReferences[2].lastName)
            || isBlankOrNull(customerData.personalReferences[2].personalReferenceType)
            || isBlankOrNull(customerData.personalReferences[2].phone.areaCode)
            || isBlankOrNull(customerData.personalReferences[2].phone.number))
        ){
            callback(false);

        } else {
			
			/* Income Source is self employed or employed */
			if(customerData.incomeSources[0].incomeType == "SELFEMPLOYED" || customerData.incomeSources[0].incomeType == "EMPLOYED" ){
				
				/* Income Source is self employed just check for sourceName is present. 
				 * Later we may need to check monthly Income as well*/
				if(customerData.incomeSources[0].incomeType == "SELFEMPLOYED"){
			 		if(isBlankOrNull(customerData.incomeSources[0].sourceName) ){
			 			callback(false);
			 		}else{
			 			callback(true);
			 		}			 		
			 		
			 	}else if(customerData.incomeSources[0].incomeType == "EMPLOYED"){
			 		
			 		/* Income Source is emplyed just check for sourceName, phone number, length of employment is present. 
			 		 *  Later we may need to check monthly Income as well*/
			 		if( isBlankOrNull(customerData.incomeSources[0].sourceName)
			 			|| isBlankOrNull(customerData.incomeSources[0].sourcePhone.number) 
			 			|| isBlankOrNull(customerData.incomeSources[0].sourcePhone.areaCode) 
			 			|| isBlankOrNull(customerData.incomeSources[0].sourceStartDate) ){
			 				
			 			callback(false);
			 			
			 		}else{
			 			callback(true);
			 		}	
			 	}
			}else{
			 		
			 		/* For all other income Source/Type don't check anything. 
			 		 * Later we may need to check monthly Income by adding a condition here */
			 		callback(true);
			}	
			
		}
		
	};

    // reporting Raygun for the error
    this.reportRaygun = function(response) {
    	//Feature 7872 Integration of Logz code changes starts
    	if(response && this.logzApiKey && this.isLogzEnabled){
    		var logger = new LogzioLogger(this.logzApiKey);
    		if(this.racCorrelatioId){
    			logger.log(this.racCorrelatioId + " - " + response);
            	return true;
    		} else {
    			return false;
    		}
    	} else {
    		return false;
    	}
    	//Feature 7872 Integration of Logz code changes ends
        /*if (typeof(Raygun) === "object") {
            var raygunKey = this.raygunKey;
            var versionNo = this.versionNo;
            var storeId = this.vanStoreId;
            if (response && raygunKey) {
                Raygun.init(raygunKey).attach().setUser(storeId).setVersion(versionNo);
                var formatResponse = JSON.stringify(response);
                try {
                  throw new Error(formatResponse);
                }
                catch(e) {
                  var customMsg = "Service Error Details: "+e.message;
                  e.message = customMsg;
                  e.name = "Service Error";
                  Raygun.send(e);
                }
            }else {
                return false;
            }
        }
        else{
            return false;
        }*/
    };

    this.createPdfBlob = function(base64Data) {
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64Data.replace(/\s/g, ''));
        // get binary length
        var len = binary.length;
        // create ArrayBuffer with binary length
        var buffer = new ArrayBuffer(len);
        // create 8-bit Array
        var view = new Uint8Array(buffer);
        // save unicode of binary data into 8-bit Array
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        // create the blob object with content-type "application/pdf"               
        var blob = new Blob( [view], { type: "application/pdf" });
        return blob;
    };

    this.clearPrintSection = function() {
        var printConatiner = document.getElementById('printSection');
        $("body").removeClass("printView");
        if(printConatiner !== null) {
            printConatiner.innerHTML = '';
        }
    }

    this.formatItemPrice = function(price) {
        var formattedPrice;
        if (String(price).indexOf('.') > -1) {
            price = String(price).split('.');
            if (price[1].length === 2) {
                formattedPrice = Number(price[0] + price[1]);
            } else {
                formattedPrice = Number(price[0] + price[1] + '0');
            }
        } else {
            formattedPrice = Number(price + '00');
        }
        return formattedPrice;
    }

    this.setStoreId = function(storeId) {
        var headers = [
            'commonHeaders',
            'commonHeadersJSON',
            'commonHeadersENCRYPT',
            'agreementHeadersENCRYPT'
        ];
        for (var i = 0; i < headers.length; i++) {
            this[headers[i]]['RAC-KIOSK-LOCATION-ID'] = storeId;
        }
        this.vanStoreId = storeId;
    };

    // AES/RSA FUNCTIONS
    var randomString = function(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    };

    this.encrypt = function(plaintext, rsa_key) {
        // console.log('840plaintext=', plaintext);
        var string_key = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        var string_iv = randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
        var key = CryptoJS.enc.Utf8.parse(string_key);
        var iv = CryptoJS.enc.Utf8.parse(string_iv);
        var encrypted = CryptoJS.AES.encrypt(plaintext, key, {iv: iv});

        //RSA encryption
        var rsa_encryption = new JSEncrypt();
/* n */ rsa_encryption.setPublicKey(rsa_key);
        var encrypted_session_key = window.atob(rsa_encryption.encrypt(encrypted.key.toString(CryptoJS.enc.Latin1)));

        //pack all that stuff
        var encoding;
        var array_size = 4 + encrypted_session_key.length + 4 + encrypted.iv.sigBytes + encrypted.ciphertext.sigBytes;
        var ab = new ArrayBuffer(array_size);
        var dv = new jDataView(ab);
        dv.writeUint32(encrypted_session_key.length, false);
        dv.writeString(encrypted_session_key, encoding = 'binary');
        dv.writeUint32(encrypted.iv.sigBytes, false);
        dv.writeString(encrypted.iv.toString(CryptoJS.enc.Latin1), encoding = 'binary');
        dv.writeString(encrypted.ciphertext.toString(CryptoJS.enc.Latin1), encoding = 'binary');
        var byte_stream = dv.getString(array_size, 0, encoding = 'binary');
        //console.log('cryptoENcrypt '+ window.btoa(byte_stream));
        return window.btoa(byte_stream);
    };

    this.decrypt = function(encoded_byte_stream, private_key) {
        var buffer = window.atob(encoded_byte_stream);
        var dv = new jDataView(buffer);
        var key_size = dv.getUint32(0, false);
        var encrypted_session_key = dv.getString(key_size, 4);
        var iv_size  = dv.getUint32(key_size + 4, false);
        var iv = CryptoJS.enc.Utf8.parse(dv.getString(iv_size, key_size + 8));
        var ciphertext = dv.getString(buffer.length - key_size - iv_size - 8, key_size + iv_size + 8);

        //unwrapp the key
        var rsa_encryption = new JSEncrypt();
        rsa_encryption.setPrivateKey(private_key);
        var session_key = rsa_encryption.decrypt(window.btoa(encrypted_session_key));
        var parsed_session_key = CryptoJS.enc.Latin1.parse(session_key);
        //decrypt using AES key;
        void 0;
        return CryptoJS.AES.decrypt({ciphertext: CryptoJS.enc.Latin1.parse(ciphertext)}, parsed_session_key, {iv: iv}).toString(CryptoJS.enc.Utf8);
    };

    this.generatekeys = function() {
        var crypt = new JSEncrypt({default_key_size: 2048});
        crypt.getKey();
        var private_key = crypt.getPrivateKey().replace(/(-----(\w+) RSA PRIVATE KEY-----|\r\n|\n|\r)/gm,'');
        var public_key = crypt.getPublicKey().replace(/(-----(\w+) PUBLIC KEY-----|\r\n|\n|\r)/gm,'');
        return [private_key, public_key];
        //console.log('keys generated...'+ SharedDataSvc.generatedkeys);
    };

    this.base64 = {};
    this.base64.encode = function(str){
        return window.btoa(encodeURIComponent(str));
        // window.btoa(unescape(encodeURIComponent( str )));
    };
    this.base64.encodeAlt = function(str){
        return window.btoa(unescape(encodeURIComponent(str)));
        // window.btoa(unescape(encodeURIComponent( str )));
    };
    this.base64.decode = function(str){
        return decodeURIComponent(window.atob(str));
    };
    this.detectCardType = function(cardNum) {
        if(this.regex){
            var cardTypes = {
                visa : new RegExp(this.regex.visa),
                mastercard : new RegExp(this.regex.masterCard),
                amex : new RegExp(this.regex.amex),
                diners : new RegExp(this.regex.diner),
                discover : new RegExp(this.regex.discover)
            };
        }        

		for ( var key in cardTypes) {
            if(key == "mastercard" && (cardTypes[key].test(cardNum.substr(0,4)))) {
                return key;
            } 
            if (cardTypes[key].test(cardNum)) {
				return key;
			}
		}
		return false;
    }
}]);

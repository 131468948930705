'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('focusme', function($parse, $timeout) {
  return {
    link: function(scope, element, attrs) {
      var model = $parse(attrs.focusme);
      scope.$watch(model, function(value) {
          $timeout(function() {
            element[0].focus();
          },100);
      });
      //element.bind('blur', function() {
        //scope.$apply(model.assign(scope, false));
      //});
    }
  }
});

(function(){

	'use strict';

	angular
	.module('myApp')
	.controller('confirmAndEditEmailCtrl', confirmAndEditEmailCtrl);

	function confirmAndEditEmailCtrl($scope, $q) {
		$scope.compareEmail = compareEmail;
		$scope.editEmail = editEmail;
		$scope.resetEmail = resetEmail;
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.verifyEmail = true;

		$scope.resetEmail();
        $scope.editEmailModeOn = false;
        $scope.isVerified = true;

		function compareEmail(el) {
			$scope.isVerified = false;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			if($scope.editEmailModeOff && !$scope.emailAddress){
				$scope.invalidEmailAddress = true;
				return false;
			} 
			if($scope.editEmailModeOff && !$scope.emailVerify){
				if(el == 'emailAddress'){
					$scope.isVerified = false;
					return false;
				} else {
					$scope.invalidEmailVerify = true;
					return false;					
				}				
			} 
			if($scope.emailAddress && $scope.emailVerify && ($scope.emailAddress.toUpperCase() != $scope.emailVerify.toUpperCase())){
				$scope.verifyEmail = false;
				return false;
			} else {
				$scope.verifyEmail = true;
				$scope.isVerified = true;
			}
		}

		function resetEmail() {	
			$scope.emailVerify = undefined;
			$scope.invalidEmailAddress = false;
			$scope.invalidEmailVerify = false;
			$scope.verifyEmail = true;
			$scope.isVerified = false;
			$scope.editEmailModeOff = false;
		}

		function editEmail(){
			$scope.editEmailModeOn = true;
			$scope.isVerified = false;
			$scope.editEmailModeOff = true;
		}
		
	}
})();
'use strict';

myApp.directive('dynamicContent', function($compile) {
    return {
        restrict: 'EA',
        replace: true,
        link: function (scope, element, attrs) {
            scope.$watch(attrs.dynamic, function() {
                $compile(element.contents())(scope);
            });
        }
    };
});
'use strict';

// use maxlength, and auto-focus to next field
myApp
.directive('maxlforward', [ function() {
    return function(scope, element, attr) {
      element.on('focus', function(event) {
          var myMax = parseInt(element.attr('maxlength'));
          var currKey;
          element.on('keyup', focusNext);
          function focusNext(event) {
            var key = event.keyCode;
            // if key entered is numeric (0-9, either top row keys or numpad)
            if ((key > 47 && key < 58) || (key > 95 && key < 106)) {
              currKey = element.val().length;
              if (currKey == myMax) {
                  // remove event from input
                  element.off('keyup', focusNext);
                  element.parent('div').next('div').children('input').focus();
              }
            }
          }
      });
    }
}]);

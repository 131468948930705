'use strict';

myApp
.directive('myLoadingSpinner', function() {
    return {
      restrict: 'A',
      replace: true,
      transclude: true,
      scope: {
        loading: '=myLoadingSpinner',
        setCenter: '=center'
      },
      templateUrl: 'partials/spinner.html',
      // template: '<div class="spinnerBox" ng-class="{center: setCenter}"><div ng-show="loading" class="my-loading-spinner-container"><img src="img/spinner-rac.svg" /></div><div ng-hide="loading" ng-transclude></div></div>',
      link: function(scope, element, attrs) {
        // var spinner = new Spinner().spin();
        setTimeout(function(){
            // var loadingContainer = element.find('.my-loading-spinner-container')[0];
            // loadingContainer.appendChild(spinner.el);
        }, 200);
      }
    };
});

'use strict';

// for obfuscation on blur
myApp
.directive('obfuscator', [ function() {
    return function(scope, element, attr) {
        element.on('focus', function(event) {
            element.attr('type','text');
        });
        element.on('blur', function(event) {
            element.attr('type','password');
        });
    }
}])
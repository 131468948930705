///////////////////////////////////////////
//////////////////////////////////////////
///   DROPDOWN CONTROLLER   /////////////
////////////////////////////////////////
///////////////////////////////////////
(function() {

	'use strict';

	angular
		.module('app.order')
		.controller('OrderBuilder', OrderBuilder);

	function OrderBuilder(
		$scope,
		$rootScope,
		$http,
		$filter,
		$timeout,
		filterFilter,
		SharedDataSvc,
		authentication,
		productCategoriesFactory,
		getStoreInfoFactory,
		// below comes from resolve when creating the modal from a controller
		modalData,
		orderService
	) {
		/*jshint validthis: true */
		var builder = this;

		$rootScope.hideMenuButtons = false;

		// function to submit the form after all validation has occurred
		builder.inventoryInfo = [];
		builder.inventoryInfo.productCategories = [];
		builder.addItemReturn = {
			quoteItem: {
				itemId: 0,
				unitType: '',
				itemDesc: '',
				qty: 0,
				discountID: '',
				price: 0,
				subType: ''
			}
		};
		builder.addItemForm = {};
		// builder.addItemForm.customAttributes = [];
		builder.editItemForm = {};

		builder.isSetMode = modalData.isSetMode;
		builder.isEditMode = modalData.isEditMode;
		builder.itemIndex = modalData.itemIndex;
		builder.isSetItem = false;

		builder.editOrderMode = false;
		//Feature 9634 	VAN - Technology and Music New Categories and Subtypes - Starts
		builder.showDepartment = true;
		builder.singletonDepartment = false;
		//Feature 9634 	VAN - Technology and Music New Categories and Subtypes - Ends
		builder.showItems = false;
		builder.showBrand = false;
		builder.showColors = false;
		builder.showOther = false;
		builder.showAccessories = false;
		builder.showQuantity = false;
		builder.showMaterials = false;
		builder.orderSub = [{
			deliveryAmt: 0,
			otherAmt: 0,
			sessionId: 0,
			taxRate: 6.5,
			extendService: 0.00,
			customer: {
				firstName: "",
				lastName: ""
			},
			quoteItem: [],
			orderSpecifics: {
				engagementId: 0,
				orderSubtotal: 0,
				calculatedTax: 0
			}
		}];
		builder.sharedDataSvc = SharedDataSvc;
		builder.noItemsMessage = true;
		builder.globalmessage = SharedDataSvc.globalmessage;
		builder.globalmessageShow = SharedDataSvc.globalmessageShow;
		builder.shownItems = [];
		builder.currentSelectionObject = {};
//		builder.itemMaximumPrice = 3000; //Story 27936 Changes
		builder.itemMaximumPrice; //Story 27936 Changes
		builder.itemMinimumPrice = 0;
		builder.stepField = 2;
		builder.minQtyField = 4;
		builder.maxQtyField = 14;
		// PUBLIC METHODS
		builder.resetFields = resetFields;
		// builder.addToOrder = addToOrder;
		builder.enterSetMode = enterSetMode;
		builder.enterEditMode = enterEditMode;
		builder.addQuantityValue = addQuantityValue;
		builder.editQuantityValue = editQuantityValue;
		builder.itemSize = []; // AC-13373
		builder.validateTotals = validateTotals;// Story 28508 Changes
		builder.resetFlag = resetFlag;// Story 28508 Changes
		builder.itemPriceCheck = itemPriceCheck;//Defect 25256 code change
		builder.isSpanishStore;
		var currentSelection = [];
		var currentSelectionObjects = [];
		var visibleFields = [];
		var quantityItems = [
			"Nightstand",
			"Filing Cabinet",
			"Bookcase",
			"Chair",
			"End Table",
			"Recliner"
		];
		var excludedCategories = [];
		//Story 32271 code chnages starts
		var excludedItems = [];
		var excludedSetItems = [];
		//Story 32271 code chnages ends
		
		/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Starts*/
		var itemPrice = [];
		/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Starts*/
		
		////////////////////////////////////////////////
		///  DATA SERVICES   ///////////////////////////
		////////////////////////////////////////////////
		init();

		function init() {
			getStoreInfoFactory.getData()
				.then(
				// Success
				function(response){
					if (response.excludedCategories) {
						excludedCategories = response.excludedCategories;
					}
					//Story 32271 code chnages starts
					if(response.excludedItems){
						excludedItems = response.excludedItems;
					} 					
					if(response.excludedSetItems){
						excludedSetItems = response.excludedSetItems;
					}
					
					/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Starts*/
					if(response.itemPrice){
						itemPrice = response.itemPrice;
					}
					/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Ends*/
					
					//Story 32271 code chnages ends
					//Story 27936 Changes starts
					builder.storeMaxPrice = response.maxItemPrice;
					$scope.minItemAmount = response.minItemAmount;
					SharedDataSvc.globalVars.storeCode = response.storeCode;//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements
					$scope.storeCode = response.storeCode;//Story 32270 code change
					/* Commenting the line below to fix 25443 uat defect */
				//	builder.itemMinimumPrice = $scope.minItemAmount;
                    builder.itemMaximumPrice = response.maxItemPrice;//this is needed because Edit screen reads the initial value of builder.itemMaximumPrice and we have set it to undefined.
                    //Story 27936 Changes ends
                    builder.mattressMaxPrice = response.mattressMaxPrice;// Story 28573 code changes
					prepareProductData();
					if($rootScope.selectedLanguage == 'es'){
						builder.isSpanishStore = true;
					}else{
						builder.isSpanishStore = false;	
					}
					
				},
				// Fail
				function(response){}
			);

		}

		function prepareProductData() {
			// add the data, map out the dropdowns
			productCategoriesFactory.getData().then(function(response) {
				void 0;
				builder.inventoryInfo = response;
				void 0;
				var productCategories = _.clone(builder.inventoryInfo.productCategories);
				// APPEND Children to their parent objects; basically re-map the JSON data
				_(productCategories).forEachRight(function(obj, index, collection) {
					if (obj.parent) {
						// find the parent object
						var parent = _.find(collection, {
							labelEn: obj.parent
						});
						// check if it already has a collection started
						if (!parent.items) {
							parent.items = [obj];
						} else {
							parent.items.push(obj);
						}
						// remove object from main collection, for organization
						_.pull(collection, obj);
					}
					if (excludedCategories.indexOf(obj.labelEn) > -1) {
						_.pull(collection, obj);
					}
					
					
				});
				


				/* Filtering the ProductCategories items as well i.e. on more level deep to filter out excluded categories present there */
				var productCat = productCategories;
				void 0;
				for(var z=0; z < productCategories.length; z++){

					if( _.isArray(productCategories[z].items) ){
						
						var filteredItem = [];
						for(var x = 0; x < productCategories[z].items.length; x++){
							
							if( excludedCategories.indexOf(productCategories[z].items[x].labelEn) == -1 ){
								filteredItem.push(productCategories[z].items[x]);
							}	
						}
						productCat[z].items = filteredItem;	
					}	
				}
				
				void 0;
				
				
				// builder.inventoryInfo = data;
				builder.productList = productCat;
				// builder.productDepartments = _.where(builder.productsList, {parent:undefined});
				builder.brandSets = builder.inventoryInfo.brandSets;
				builder.colorSets = builder.inventoryInfo.colorSets;
				builder.materialSets = builder.inventoryInfo.materialSets;
				SharedDataSvc.globalVars.itemGroupVendorSet = builder.inventoryInfo.itemGroupVendorSet;//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements
				builder.minimumPriceToDeduct = builder.inventoryInfo.minimumPriceToDeduct.priceToDeduct;//Feature 8384 - AWP, Kiosk, Services: Each item must have a maximum $ amount, VENDOR level setting
				if (builder.isSetMode) {
					enterSetMode(modalData.itemIndex);
				}
				if (builder.isEditMode) {
					void 0;
					enterEditMode(modalData.itemIndex);
				}
				//Feature 9634 	VAN - Technology and Music New Categories and Subtypes - Starts
				if(productCat.length == 1 && productCat[0].items.length == 1)
				{
					builder.showDepartment = false;
					builder.singletonDepartment = true;
					showField('showItems');
					hideField(['showCategory', 'showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
					var items = [];
					for(var x=0; x<productCat[0].items[0].items.length; x++){
						items.push(productCat[0].items[0].items[x]);
					}
					builder.items = items;
				}
				//Feature 9634 	VAN - AWP/Kiosk Technology and Music New Categories and Subtypes - Ends
			}); 
			
				
		}
		
		////////////////////////////////////////////////
		///  WATCHERS   ////////////////////////////////
		////////////////////////////////////////////////
		$scope.$watch("builder.currentSelectionObject", function(newValue, oldValue) {
			// console.log("Changed", currentSelection, newValue);
		});

		// SELECTION - DEPARTMENT
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedDepartmentItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				//AC 15028 code changes starts
				hideField(['showCategory', 'showItems', 'showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				builder.currentSelectionObject = {};
				//AC 15028 code changes ends
				return;
			}
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if (newValue.dependencyAlertMessageEn) {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}
			else{
				builder.productMessage = '';
			}*/
			builder.productMessage = '';
			//VAN-2881-Remove ALL Item Dependencies -Ends
			if (newValue.generalCategoryAlertMessageEn) {
				builder.generalCategoryMessage = newValue.generalCategoryAlertMessageEn;
			}
			else{
				builder.generalCategoryMessage = '';
			}
			
			setSelections('selectedDepartmentItem', newValue);
			showField('showCategory');
			hideField(['showItems', 'showBrands', 'showMaterials', 'showCustomAttributes']);
		});

		// SELECTION - CATEGORY
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedCategoryItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				hideField(['showItems', 'showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				return;
			}
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if (newValue.dependencyAlertMessageEn) {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}else{
				builder.productMessage = '';
			}*/
			builder.productMessage = '';
			//VAN-2881-Remove ALL Item Dependencies -Ends
			if (newValue.generalCategoryAlertMessageEn) {
				builder.generalCategoryMessage = newValue.generalCategoryAlertMessageEn;
			}
			else{
				builder.generalCategoryMessage = '';
			}

			setSelections('selectedCategoryItem', newValue);
			if (newValue.items && newValue.items != '') {
				showField('showItems');
				hideField(['showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				//Story 32271 code chnages starts
				if( _.isArray(newValue.items) && !builder.isSetMode ){
					var filteredItem = [];
					for(var y=0; y<newValue.items.length; y++){
						if( excludedItems.indexOf(newValue.items[y].labelEn) == -1 ){
							filteredItem.push(newValue.items[y]);
						}	
					}
					newValue.items = filteredItem;	
				}
				//Story 32271 code chnages ends
			} else if (newValue.brandSet) {
				builder.itemBrands = _.find(builder.brandSets, {
					name: newValue.brandSet
				}).brands;
				showField('showBrands');
				hideField(['showItems', 'showMaterials', 'showCustomAttributes', 'showColors']);
			} else if (newValue.materialSet) {
				builder.itemMaterials = _.find(builder.materialSets, {
					name: newValue.materialSet
				}).materials;
				showField('showMaterials');
				hideField(['showItems', 'showBrands', 'showCustomAttributes', 'showColors']);
			}
		});

		// SELECTION - CATEGORY ITEM
		// ===============================================================================
		$scope.$watchCollection("builder.addItemForm.selectedItemItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				hideField(['showBrands', 'showMaterials', 'showAccessories', 'showCustomAttributes', 'showColors']);
				return;
			}
			void 0;

			setSelections('selectedItemItem', newValue);

			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
			//VAN-2881-Remove ALL Item Dependencies -Starts
			/*if (newValue.dependencyAlertMessageEn && $rootScope.selectedLanguage=="en") {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}else if(newValue.dependencyAlertMessageEs && $rootScope.selectedLanguage=="es" ){
				builder.productMessage = newValue.dependencyAlertMessageEs;
			}
			else{
				builder.productMessage = '';
			}*/
			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/ 
			//checkDependencyItems(newValue,SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems); // VAN-2502 Changes
			builder.productMessage = '';
			//VAN-2881-Remove ALL Item Dependencies -Ends
			// VAN 2535 Changes Start
			if(newValue.qtyFieldDefault){
				builder.addItemForm.quantity = newValue.qtyFieldDefault;
			}

			// Looks for a sub-level mentioning "Accessories" in the selected item
			var hasAccessories = newValue.labelEn.indexOf("Accessories") > 0;
			if (hasAccessories) {
				// show them if it exists
				if(newValue.items){
					builder.itemAccessories = newValue.items;
					showField('showAccessories');
					hideField(['showBrands', 'showMaterials', 'showCustomAttributes', 'showColors']);
				}else{
					hideField(['showBrands', 'showAccessories', 'showMaterials', 'showCustomAttributes', 'showColors']);
				}
			} else if (newValue.brandSet) {
				// If the item has brands, show them
				showField('showBrands');
				builder.itemBrands = _.find(builder.brandSets, {
					name: newValue.brandSet
				}).brands;
				hideField(['showAccessories', 'showMaterials', 'showCustomAttributes', 'showColors']);
			} else if (newValue.materialSet) {
				builder.itemMaterials = _.find(builder.materialSets, {
					name: newValue.materialSet
				}).materials;
				showField('showMaterials');
			} else {
				hideField(['showBrands', 'showAccessories', 'showMaterials', 'showCustomAttributes', 'showColors']);
			}

			hideField('showQuantity');
			if (quantityItems.indexOf(builder.addItemForm.selectedItemItem.labelEn) > -1) {
				showField('showQuantity');
			}
			if (builder.addItemForm.selectedItemItem.customAttributeGroups.length > 0) {
				builder.customAttributeGroups = builder.addItemForm.selectedItemItem.customAttributeGroups;
				showField('showCustomAttributes');
			}else {//AC 15028 code changes starts
				builder.customAttributeGroups = [];
				hideField('showCustomAttributes');
			}//AC 15028 code changes ends
		});

		// VAN-2502 Changes Start
		function checkDependencyItems(newValue,orderItems)
		{
			var isDependantAlreadyExists = false;
			for(var i=0;i<orderItems.length;i++)
			{
				var setItem = orderItems[i];
				if(setItem.setItems.length != 'undefined')
				{
					for(var j=0;j<setItem.setItems.length;j++)
					{
						var eachItem = setItem.setItems[j];
						if(newValue.dependencies && newValue.dependencies.length > 0){ //AC 13512 code changes
							for(var k=0;k<newValue.dependencies.length;k++)
							{
								if(eachItem.category === (newValue.dependencies[k].dependentProduct))
								{
									isDependantAlreadyExists=true;
								}
							}
						}//AC 13512 code changes
					}
				}
			}
			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
			if (!isDependantAlreadyExists && $rootScope.selectedLanguage=="en" ) {
				builder.productMessage = newValue.dependencyAlertMessageEn;
			}else if(!isDependantAlreadyExists && $rootScope.selectedLanguage=="es"){
				builder.productMessage = newValue.dependencyAlertMessageEs;
			}else {
				builder.productMessage = '';
			}
			/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
		}
		// VAN-2502 Changes End

		// SELECTION - BRAND
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedBrandItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			} else if (newValue === null) {
				return;
			}
			setSelections('selectedBrandItem', newValue);
			builder.showOther = false;
			builder.showColors = false;

			checkColorSet(builder.addItemForm.selectedCategoryItem);

			// Check if an item level is selected, then look for custom attributes
			if (typeof builder.addItemForm.selectedItemItem != "undefined") {
				if (builder.addItemForm.selectedItemItem.customAttributeGroups.length > 0) {
					builder.customAttributeGroups = builder.addItemForm.selectedItemItem.customAttributeGroups;
					showField('showCustomAttributes');
				}
				checkColorSet(builder.addItemForm.selectedItemItem);
			} else if (builder.addItemForm.selectedCategoryItem.customAttributeGroups.length > 0) {
				builder.customAttributeGroups = builder.addItemForm.selectedCategoryItem.customAttributeGroups;
				showField('showCustomAttributes');
			} else {
				//AC 15028 code changes starts
				builder.customAttributeGroups = [];
				hideField('showCustomAttributes');
				//AC 15028 code changes ends
			}
		});

		// SELECTION - MATERIAL
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedMaterialsItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			if (newValue === null) {
				builder.showColors = false;
				return;
			}
			setSelections('selectedMaterialsItem', newValue);
			builder.showColors = false; //VAN-2501

			checkColorSet(newValue);
		});
		$scope.$watch("builder.editItemForm.selectedMaterialsItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			checkColorSet(newValue);
		});

		// SELECTION - COLOR
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedColorItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}

			// Added for VAN-2501
			if (newValue === null) {
				return;
			}
			// End

			setSelections('selectedColorItem', newValue);
			// $scope.currentSelectionObject = newValue;

			hideField('showOther');
			if (newValue.labelEn === 'Other') {
				showField('showOther');
			};
		});

		// SELECTION - ACCESSORY
		// ===============================================================================
		$scope.$watch("builder.addItemForm.selectedAccessoryItem", function(newValue, oldValue) {
			if (newValue === oldValue) {
				return;
			}
			if (newValue === null) {
				builder.showAccessoryBrand = false;
				return;
			}
			setSelections('selectedAccessoryItem', newValue);

			// Set the accessory brand set
			if (newValue.brandSet) {
				// Use the accessory's brandset if it has one
				builder.accessoryBrandLabel = newValue.labelEn;
				builder.accessoryBrands = _.find(builder.brandSets, {
					name: newValue.brandSet
				}).brands;
				showField('showAccessoryBrand');
			} else {
				hideField("showAccessoryBrand");
				// Otherwise assume the accessory's parent has a brand set
				/*builder.accessoryBrandLabel = "Brand";
				builder.accessoryBrands = _.find(builder.brandSets, {
					name: builder.addItemForm.selectedItemItem.brandSet
				}).brands; */
			}
			
		});

		$scope.$watch("builder.addItemForm.selectedBedSizeItem", function(newValue, oldValue) {
			if (newValue === oldValue) return;
			setSelections('selectedBedSizeItem', newValue);
		});

		/////////////////////////////////////////////////////
		/////   METHODS  ////////////////////////////////////
		/////////////////////////////////////////////////////

		function setSelections(modelName, newObject) {
			if(newObject){ //AC 15028 code changes
				builder.currentSelectionObject = newObject;
			}
			if (currentSelection.indexOf(modelName) < 0) {
				currentSelection.push(modelName);
				currentSelectionObjects.push(newObject);
			} else {
				var i = currentSelection.length - 1;
				var modelIndex = currentSelection.indexOf(modelName);
				if (i > modelIndex) { //Story 27936 code Changes - when changing different level dropdown
					while(i>modelIndex){
					builder.addItemForm[currentSelection[i]] = null;
					currentSelection.pop();
					currentSelectionObjects.pop();
					i--;
					}
				}
				if(i == modelIndex){ //Story 27936 code Changes starts - when changing same level dropdown. replace old val with new value. 
					currentSelectionObjects.pop();
					currentSelectionObjects.push(newObject)
				}
				//Story 27936 code Changes ends
			}

			//Story 27936 Changes starts
			//builder.itemMaximumPrice = 3000; 
			builder.itemMinimumPrice = $scope.minItemAmount;
			builder.isMaxPriceSet = false;
			if((modelName == "selectedItemItem" || modelName == "selectedCategoryItem" || modelName == "selectedDepartmentItem") && builder.addItemForm && addItemForm.itemPrice && addItemForm.itemPrice.value){
				builder.addItemForm.itemPrice = '';
				builder.invalidItemPrice=false;// Story 28508 Changes
			}
			//Story 27936 Changes ends
			_.forEachRight(currentSelectionObjects, function(obj) {
				/*//Story 28573 code changes starts
				if(obj && obj.iconURL == "MattressBoxSpring"){
					builder.itemMaximumPrice = builder.mattressMaxPrice;
					return;
				}//Story 28573 code changes ends
				if (_(obj).has('maximumPrice')) {
				builder.itemMaximumPrice = obj.maximumPrice;
				builder.isMaxPriceSet = true;//Story 27936 code changes
				return;}*/
				
				/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - starts*/
					if(itemPrice.length!=0 && itemPrice.length!=undefined){
						for(var k=0;k<itemPrice.length;k++){
							if(obj.labelEn == itemPrice[k].item){
								builder.itemMaximumPrice =  itemPrice[k].maxPrice;
								builder.isMaxPriceSet = true;
							}
						}
					}
				/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Ends*/
					
				//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Starts
				/*if(_(obj).has('maximumPriceVendorSet')){
					_.forEachRight(obj.maximumPriceVendorSet, function(vendorLst){
						if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
							builder.itemMaximumPrice = vendorLst.maximumPrice;
							builder.isMaxPriceSet = true;
						}
					});
				} */
				if(_(obj).has('maximumPrice') && !builder.isMaxPriceSet){
					//Story 32270 code changes ends
					builder.itemMaximumPrice = obj.maximumPrice;
					builder.isMaxPriceSet = true;
						}
				//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Ends
				else{//Story 27936 code changes starts
					if(!builder.isMaxPriceSet && !(_(obj).has('items')))
					{
						builder.itemMaximumPrice = builder.storeMaxPrice;
						return;
					}
				}
				//Story 27936 code changes ends
			});
			_.forEachRight(currentSelectionObjects, function(obj) {
			//Story 32270 code changes starts
				var isMinPriceSet = false;
				if(_(obj).has('minimumPriceVendorSet')){
					_.forEachRight(obj.minimumPriceVendorSet, function(vendorLst){
						if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
							builder.itemMinimumPrice = vendorLst.minimumPrice;
							isMinPriceSet = true;
						}
					});
				} 
				if(_(obj).has('minimumPrice') && !isMinPriceSet){
			//Story 32270 code changes ends
					builder.itemMinimumPrice = obj.minimumPrice;
					return;
				}
			});
		}

		function showField(field) {
			builder[field] = true;
			if (visibleFields.indexOf(field) < 0) {
				visibleFields.push(field);
			}
		}

		function hideField(fields) {
			if (_.isArray(fields)) {
				_.forEach(fields, function(field) {
					builder[field] = false;
				});
			} else {
				builder[fields] = false;
			}
		}

		function flattenItems(itemArray) {
			var items = [];
			_(itemArray).forEach(function(obj) {
				_(obj.items).forEach(function(item) {
					items.push(item)
				});
			});
			return items;
		}

		function resetFields(selectionLevel) {
			var resetFields = [
				builder.showCategory,
				builder.showBrand,
				builder.showAccessories
			];
			switch (selectionLevel) {
				case 'category':
					resetFields = _.without(resetFields, 'showCategory');
					break;
				case 'items':
					resetFields = _.without(resetFields, 'showCategory', 'showItems');
			}
			for (var i = 0; i < resetFields.length; i++) {
				resetFields[i] = false;
			}
		}

		function addQuantityValue(increaseValue){
			if(builder.addItemForm.quantity == undefined || builder.addItemForm.quantity == null){
				builder.addItemForm.quantity = builder.addItemForm.selectedItemItem.qtyFieldDefault;
				return;
			}
			if(increaseValue){
				builder.addItemForm.quantity += builder.addItemForm.selectedItemItem.qtyFieldIncrement;
			}
			else{
				builder.addItemForm.quantity -= builder.addItemForm.selectedItemItem.qtyFieldIncrement;
			}
		}
		//Story 28508 Changes Start
		function resetFlag(){
			builder.invalidItemPrice=false;
		}
		
		//Defect 25256 Code changes start
		function itemPriceCheck(itemPrice) {
			if (itemPrice && String(itemPrice).indexOf('.') > -1) {
				var itemPrices = String(itemPrice).split('.');
				if (itemPrices[1].length > 2) { 
					if(builder.editItemForm.itemPrice) {
						builder.editItemForm.itemPrice = Number(itemPrice.toString().match(/^\d+(?:\.\d{0,2})?/));
					} else {
						builder.addItemForm.itemPrice = Number(itemPrice.toString().match(/^\d+(?:\.\d{0,2})?/));
					}		
			
				}
			}
		}
		//Defect 25256 Code changes end
		
		function validateTotals(isSetMode,formName,isEditMode,index) {
			builder.orderSubtotal = 0.00;
			if(SharedDataSvc.globalVars.order.currentOrderSubTotal){
				builder.orderSubtotal = SharedDataSvc.globalVars.order.currentOrderSubTotal;
			}
			if(SharedDataSvc.globalVars.order && SharedDataSvc.globalVars.order.approvalAmount){
				builder.approvalamount=SharedDataSvc.globalVars.order.approvalAmount;
			}
			if(builder.approvalamount < 0){
				builder.remainingApprovalAmount=0;
				builder.invalidItemPrice=true;
				builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_validateTotalError : en.orderBuilder_label_validateTotalError;
				return; // Defect 23235 changes
			}
			var savedItemPrice;
			if(isEditMode){
				savedItemPrice = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[index].itemPrice;
				builder.remainingApprovalAmount = ((Math.round((builder.approvalamount)*100)/100)-((Math.round((builder.orderSubtotal)* 100 ) / 100)-(Math.round((savedItemPrice)* 100 ) / 100)));
				if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length>1)
					{
						builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_RAAmtError : en.orderBuilder_label_RAAmtError;
					}
				else
					{
						builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_AAmtError : en.orderBuilder_label_AAmtError;
					}
			}else{
				builder.remainingApprovalAmount = (Math.round((builder.approvalamount)*100)/100)-(Math.round((builder.orderSubtotal)* 100 ) / 100);
				if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length>0){
					builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_RAAmtError : en.orderBuilder_label_RAAmtError;
				}
				else{
					builder.errormessage = $rootScope.selectedLanguage == 'es' ? es.orderBuilder_label_AAmtError : en.orderBuilder_label_AAmtError;
				}
			}
			builder.remainingApprovalAmount = builder.remainingApprovalAmount.toFixed(2);
			
			if(builder.remainingApprovalAmount<0 || builder.remainingApprovalAmount < builder[formName].itemPrice){
				builder.remainingApprovalAmount=builder.remainingApprovalAmount<0?0:builder.remainingApprovalAmount;
				builder.errormessage += builder.remainingApprovalAmount;				
				builder.invalidItemPrice=true;
				return;
			}else{
				if(isSetMode.isSetMode === false){
					$scope.$close({item: builder[formName]});
				}
				else{
					$scope.$close({item: builder[formName], isSetMode: true});
				}
				builder.invalidItemPrice=false;
				return;
			}
		};
		//Story 28508 Changes End
		function editQuantityValue(increaseValue){
			if(builder.editItemForm.quantity == undefined || builder.editItemForm.quantity == null){
				builder.editItemForm.quantity = builder.editItemForm.selectedCategoryItem.qtyFieldDefault;
				return;
			}
			if(increaseValue){
				builder.editItemForm.quantity += builder.editItemForm.selectedCategoryItem.qtyFieldIncrement;
			}
			else{
				builder.editItemForm.quantity -= builder.editItemForm.selectedCategoryItem.qtyFieldIncrement;
			}
		}

		function checkColorSet(selection) {
			if (selection && selection.colorSet) { //AC 13870 code changes
				builder.itemColors = _.find(builder.colorSets, {
					name: selection.colorSet
				}).colors;
				showField('showColors');
				return;
			}
			
			// no color set, in edit mode
			if (builder.isEditMode) {
				builder.editItem.color = selection.colorSet; //AC 13870 code changes
				builder.editItemForm.selectedColorItem = { labelEn: ''};
			}
		}

		// function
		function enterSetMode(setIndex) {
			void 0;
			builder.addItemForm.selectedCategoryItem = {};
			builder.isSetItem = true;
			var set = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[setIndex];
			if (!setIndex) {
				// return;
			}
			// get category name string
			
			/*VAN 488 - AWP- In Spanish Flow for PR stores, Not able to add set items - Starts*/
			var setCategory;
			if($rootScope.selectedLanguage == 'en'){
				setCategory = _.find(builder.inventoryInfo.productCategories, { labelEn: set.category }).parent;
			}if($rootScope.selectedLanguage == 'es'){
				setCategory = _.find(builder.inventoryInfo.productCategories, { labelEs: set.category }).parent;
			}
			/*VAN 488 - AWP- In Spanish Flow for PR stores, Not able to add set items - Ends*/
			
			var selectedCategoryItem = _.find(builder.inventoryInfo.productCategories, {labelEn: setCategory});
			//Story 32271 code chnages starts
			if( _.isArray(selectedCategoryItem.items) ){
				var filteredSetItem = [];
				for(var x=0; x<selectedCategoryItem.items.length; x++){
					if( excludedSetItems.indexOf(selectedCategoryItem.items[x].labelEn) == -1 ){
						filteredSetItem.push(selectedCategoryItem.items[x]);
					}	
				}
				selectedCategoryItem.items = filteredSetItem;	
			}
			//Story 32271 code chnages ends
			builder.addItemForm.selectedCategoryItem = selectedCategoryItem;
		}

		function enterEditMode(itemIndex) {
			void 0;
			var orderItem;
			var products = builder.inventoryInfo.productCategories;

			if (itemIndex.length > 1) {
				orderItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex[0]].setItems[itemIndex[1]];
				builder.isSetMode = true;//AC Defect 26760 - VAN-Web-Unable to edit set item
				builder.isSetItem = true;
			} else {
				orderItem = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex];
			}
			void 0;
			/*
			 Start with the base product tier of the item, can be retrieved from the iconURL
			 A few items have the same iconURL, so get the last one found as it's deepest level
			 that needs to be found
			 */
			/* VAN - 2552 patch fix for bed category*/
			var itemBaseTier;
			if(orderItem.iconURL == 'Bed'){
				itemBaseTier = _(products).where({iconURL: orderItem.iconURL}).first(); // AC-13373 Changes
			}else{
				itemBaseTier = _(products).where({iconURL: orderItem.iconURL}).last();
			}
			builder.editItem = orderItem;


			if (orderItem.brand) {
				// Set the brand set from base
				var itemBrands = _.find(builder.brandSets, {
					name: itemBaseTier.brandSet
				}).brands;
				var selectedBrand = _.find(itemBrands, {
					label: orderItem.brand
				});

				builder.itemBrands = itemBrands;
				builder.editItemForm.selectedBrandItem = selectedBrand;
			}

			if (orderItem.material) {
				// Set editable materials using base item set
				var itemMaterials = _.find(builder.materialSets, {
					name: itemBaseTier.materialSet
				}).materials;
				var selectedMaterial = _.find(itemMaterials, {
					labelEn: orderItem.material
				});

				builder.itemMaterials = itemMaterials;
				builder.editItemForm.selectedMaterialsItem = selectedMaterial;
			}

			if (orderItem.color) {
				// determine where the color set is, either main item or materials
				var colorSetName = _(itemBaseTier).has('colorSet') ? itemBaseTier.colorSet : builder.editItemForm.selectedMaterialsItem.colorSet;
				if((colorSetName != null) && (colorSetName != undefined)){//AC 13870 code changes
					var itemColors = _.find(builder.colorSets, {
						name: colorSetName
					}).colors;
					// console.log(builder.itemColors);
					var selectedColor = _.find(itemColors, {
						labelEn: orderItem.color
					});
					builder.itemColors = itemColors;
					builder.editItemForm.selectedColorItem = selectedColor;
				}//AC 13870 code changes
			}
			//AC-13373 Changes Start
			if (orderItem.size) {
				if((itemBaseTier.items) && (itemBaseTier.items.length>0)){
					var itemSize = [];
					for(var i =0; i < itemBaseTier.items.length; i++){
						itemSize.push(itemBaseTier.items[i]);
					}
					builder.itemSize = itemSize;
					for(var i=0;i<builder.itemSize.length;i++){
						if(builder.itemSize[i].labelEn == orderItem.size){	
							builder.editItemForm.selectedBedSizeItem = builder.itemSize[i];
							break;
						}
					}
				}
			}			
			//AC-13373 Changes End
			//Story 32270 code changes starts
			var isMinPriceSetForEdit = false;
			if(itemBaseTier.minimumPriceVendorSet){
				_.forEachRight(itemBaseTier.minimumPriceVendorSet, function(vendorLst){
					if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
						builder.itemMinimumPrice = vendorLst.minimumPrice;
						isMinPriceSetForEdit = true;
					}
				});
			}
			if(itemBaseTier.minimumPrice && !isMinPriceSetForEdit){
			//Story 32270 code changes ends
				builder.itemMinimumPrice = itemBaseTier.minimumPrice;
			}else if(!isMinPriceSetForEdit){//AC26729 code changes
				/* Added this else condition to fix 25443 uat defect */
				builder.itemMinimumPrice = $scope.minItemAmount;
			}
			
			/*//AC 23123 code changes starts
			if(itemBaseTier && itemBaseTier.iconURL == "MattressBoxSpring"){
				builder.itemMaximumPrice = builder.mattressMaxPrice;
				//AC 23123 code changes ends
			} else if(itemBaseTier.maximumPrice){
				builder.itemMaximumPrice = itemBaseTier.maximumPrice;
			}*/
			
			/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - starts*/
			var isMaxPriceSetAtVendor = false;
			if(itemPrice.length!=0 && itemPrice.length!=undefined){
				for(var k=0;k<itemPrice.length;k++){
					if(itemBaseTier.labelEn == itemPrice[k].item){
						builder.itemMaximumPrice = itemPrice[k].maxPrice;
						isMaxPriceSetAtVendor = true;
					}
				}
			}
			/*Feature 9517 - AWP, Kiosk, Services: Each item must have a maximum $ amount, STORE level setting - Ends*/
			
			//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Starts
			/*if(itemBaseTier.maximumPriceVendorSet){
				_.forEachRight(itemBaseTier.maximumPriceVendorSet, function(vendorLst){
					if($scope.storeCode && (vendorLst.vendor.toLowerCase() == $scope.storeCode.toLowerCase())){
						builder.itemMaximumPrice = vendorLst.maximumPrice;
						isMaxPriceSetAtVendor = true;
					}
				});
			}*/
			if(itemBaseTier.maximumPrice && !isMaxPriceSetAtVendor){
			builder.itemMaximumPrice = itemBaseTier.maximumPrice;
			isMaxPriceSetAtVendor = true;
			}
			else if(!isMaxPriceSetAtVendor){
				builder.itemMaximumPrice = builder.storeMaxPrice;
			}
			//Feature 8384 - AWP - Each vendor must have a maximum $ amount, vendor level setting - Ends
			builder.editItemForm.selectedCategoryItem = itemBaseTier;
			
			//AC-13373 Changes Start
			if (builder.editItemForm.selectedCategoryItem.customAttributeGroups.length > 0) {
				builder.editItemForm.customAttributes = builder.editItemForm.selectedCategoryItem.customAttributeGroups;
				for(var i=0, k = 0;i<builder.editItemForm.customAttributes.length;i++){
					for(var j = 0; j < builder.editItemForm.customAttributes[i].customAttributes.length; j++, k++){
						if(builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "radio"){
							for(var u=0; u<builder.editItemForm.customAttributes[i].customAttributes[j].customAttributeOptions.length; u++){
								builder.editItemForm.customAttributes[i].customAttributes[j].customAttributeOptions[u].selectedAttribute = orderItem.attributes[i].value;
							}
						} else if(builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "integer" || builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "quantity"){
							builder.editItemForm.customAttributes[i].customAttributes[j].selectedTextAttribute = parseInt(orderItem.attributes[k].value);
						}else if(builder.editItemForm.customAttributes[i].customAttributes[j].fieldType == "text"){
							builder.editItemForm.customAttributes[i].customAttributes[j].selectedTextAttribute = orderItem.attributes[k].value;
						}
						else{
							builder.editItemForm.customAttributes[i].customAttributes[j].selectedAttribute = orderItem.attributes[k].value;
						}
						
					}
				}
				showField('showCustomAttributes');
			}//AC-13373 Changes End
			
			builder.editItemForm.itemPrice = orderItem.itemPrice;
			builder.editItemForm.quantity = orderItem.quantity;
			// VAN - 2430 Changes Start
			builder.editItemForm.itemModelNumber=orderItem.model;
			// VAN - 2430 Changes End
			/* VAN - 2552 patch fix bed description got changed so we are setting it again.*/
			if(orderItem.iconURL == 'Bed') {
				builder.editItemForm.selectedCategoryItem.labelEn = orderItem.itemDescription;
			}
		}

		function findColorSet(){}
	} // end .controller defs

})();
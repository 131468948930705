(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('datesFactory', dates);


	function dates($log, $q) {
		var quoteDates = [],
				monthNames = [ "January", "February", "March", "April", "May", "June",
    									 "July", "August", "September", "October", "November", "December" ];

		return {
			quoteDates: quoteDates,
			getCurrentDate: getCurrentDate,
			getFirstPaymentDate: getFirstPaymentDate,
			setQuoteDates: setQuoteDates,
			getDateByDaysFromNow:getDateByDaysFromNow
		};
		
		function getDateByDaysFromNow(val){
			var deferred = $q.defer();
			var theDate = moment().add(val,'days');
			
			deferred.resolve(theDate);
			
			return deferred.promise;
		}

		function getCurrentDate() {
	      var today = moment().format('YYYY-MM-DD HH:MM:SS');
	      return today;
		}

		function getMonthAsNumber(date) {
			date = moment(date);
			if(date.month) {
				return date.month();
			}
		}

		function getDateArray(date) {
			if(date) {
				var year = parseInt(date.substr(0,4)),
						month = parseInt(date.substr(5,2)) - 1,
						day = parseInt(date.substr(8,2));

				return [year, month, day];
			}
		}

		function getDayAsNumber(date) {
			var day = parseInt(date.substr(8,2));
			return day;
		}

		function getFirstPaymentDate(currentDate) {
			currentDate = currentDate || getCurrentDate();

			// console.log('current date', currentDate);
			// currentDate = moment('2015-04-01').format('YYYY-MM-DD HH:MM:SS');
			// console.log('current date', currentDate);

			if(currentDate) {
				var dateArray = getDateArray(currentDate),
						currentDate = moment(dateArray),
						roundedDate = moment(currentDate.add(14, 'd')._d).format('YYYY-MM-DD HH:MM:SS'),
						roundedDay = roundedDate.substr(8,2),
						currentMonth = getMonthAsNumber(getCurrentDate()),
						roundedMonth = getMonthAsNumber(roundedDate),
						month = currentMonth === roundedMonth ? currentMonth : roundedMonth;
						/*firstOfNextMonthDate = moment([dateArray[0], month + 1, 1]).format('YYYY-MM-DD HH:MM:SS'),
						fifteenthOfNextMonthDate = moment([dateArray[0], month , 15]).format('YYYY-MM-DD HH:MM:SS');*/
				
				/* Fix when current date is in month of December */
				if(currentMonth === 11){
					var firstOfNextMonthDate = moment([dateArray[0] + 1, 0, 1]).format('YYYY-MM-DD HH:MM:SS'),
					fifteenthOfNextMonthDate = moment([dateArray[0] + 1, 0, 15]).format('YYYY-MM-DD HH:MM:SS');
				}else{
					var firstOfNextMonthDate = moment([dateArray[0], month + 1, 1]).format('YYYY-MM-DD HH:MM:SS');
					fifteenthOfNextMonthDate = moment([dateArray[0], month , 15]).format('YYYY-MM-DD HH:MM:SS');
				}
				

				// console.log('rounded date', roundedDate);
				// console.log('rounded day', roundedDay);
				// console.log('rounded month', roundedMonth);
				// console.log('current month', currentMonth);
				// console.log('first of next month', firstOfNextMonthDate);
				// console.log('fifteen', fifteenthOfNextMonthDate);

				if((roundedDay === '01' || Number(roundedDay) < 15) && currentMonth < 11) {
					// console.log('DATE RETURNED', moment([dateArray[0], month, 15]).format('YYYY-MM-DD HH:MM:SS'));
					return moment([dateArray[0], month, 15]).format('YYYY-MM-DD HH:MM:SS');
				} else {
					return Number(roundedDay) >= 15 ? firstOfNextMonthDate : fifteenthOfNextMonthDate;
				}
			}
		}

		function setQuoteDates(currentDate) {
			if(currentDate) {
				var firstPaymentDate = getFirstPaymentDate(currentDate),
						dateArray = getDateArray(firstPaymentDate),
						secondPaymentDate = moment(getNextPaymentDate(firstPaymentDate)._d).format('YYYY-MM-DD HH:MM:SS'),
						thirdPaymentDate = moment(getNextPaymentDate(secondPaymentDate)._d).format('YYYY-MM-DD HH:MM:SS'),
						fourthPaymenDate = moment(getNextPaymentDate(thirdPaymentDate)._d).format('YYYY-MM-DD HH:MM:SS');

				return [{'date': firstPaymentDate, 'nextDate': firstPaymentDate,
								'day' : getDayAsNumber(firstPaymentDate), 'month' : monthNames[getMonthAsNumber(firstPaymentDate)]
								},
							  {'date': secondPaymentDate, 'nextDate': secondPaymentDate,
							  'day' : getDayAsNumber(secondPaymentDate), 'month' : monthNames[getMonthAsNumber(secondPaymentDate)]
								},
								{'date': thirdPaymentDate, 'nextDate': thirdPaymentDate,
							  'day' : getDayAsNumber(thirdPaymentDate), 'month' : monthNames[getMonthAsNumber(thirdPaymentDate)]
								}];
			}
		}

		function getNextPaymentDate(paymentDate) {
			var dateArray = getDateArray(paymentDate),
					month = dateArray[2] < 15 ? dateArray[1] : dateArray[1] + 1,
					nextPaymentDate = dateArray[2] >= 15 ? moment([dateArray[0], month, 1]) : moment([dateArray[0], dateArray[1], 15]);

			return nextPaymentDate;
		}

	}
})();
'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('originator', function() {
  return {
    template: '<span class="glyphicon glyphicon-info-sign" title="Customer created via:" data-trigger="hover " data-container="body" data-toggle="popover" data-placement="top" data-content="{{created}}"></span>',
    restrict: 'A',
    scope: {
      created:"="
    },
    link: function(scope, element, attrs) {
      $(function () {
        $('[data-toggle="popover"]').popover();
      });
    }
  }
});

'use strict';

myApp.directive('customFooter', function() {
  return {
    restrict: 'EA',
    templateUrl: 'partials/customFooter.html',
    scope: true,
    replace: true,
    controller: 'customFooterController'
  }
});
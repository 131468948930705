// This controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('manualOverride', ManualOverride);

  ManualOverride.$inject = ['$scope',
                       '$log',
                       '$routeParams',
                       '$q',
                       '$modal',
                       '$route',
                       '$location',
                       '$cookieStore',
                       'SharedDataSvc',
                       'base64',
                       'manualOverrideFactory',
                       'modalService',
                       'applicationService',
                       'authentication',
                       'updateEngagementItemFactory',
                       'productCategoriesFactory',
                       '$translate',
                       '$rootScope'
                       ];

  function ManualOverride($scope,
                     $log,
                     $routeParams,
                     $q,
                     $modal,
                     $route,
                     $location,
                     $cookieStore,
                     SharedDataSvc,
                     base64,
                     manualOverrideFactory,
                     modalService,
                     applicationService,
                     authentication,
                     updateEngagementItemFactory,
                     productCategoriesFactory,
                     $translate,
                     $rootScope
                    ) {
    /*jshint validthis: true */
    var vm = this;

    // set scope variable for processing modal
    $scope.title = 'Processing';

    // Variables
    vm.errors = manualOverrideFactory.errorModel;
    vm.modal;
    vm.item = SharedDataSvc.manualOverrideData;
    vm.storeInfo = SharedDataSvc.globalVars.storeSetup;
    vm.currentAmount = formatApprovalAmount(SharedDataSvc.manualOverrideData.approvalAmount);
    vm.productCat;
    vm.overrideOptions;
    vm.engagement;
    vm.review = manualOverrideFactory.associateReview;
    vm.overrideState = SharedDataSvc.manualOverrideData.state;
    vm.engagementId = SharedDataSvc.manualOverrideData.engagementId;
    vm.approval = manualOverrideFactory.approvalModel;
    vm.message = SharedDataSvc.manualOverrideMessage;
    vm.customer;
    vm.overrideSpec = {
      customerId: null, // <Mandatory Field>
      customMessage: "",
      overrideCode : 1, // <Mandatory Field>
      overrideAmount : vm.approval.requestedAmount, // [Amount that approval was increased]
      overrideStatus : "APPROVE", // [New Approval Status]
      employeeId : "Test"
    };
    vm.noEmpPass = false;
    vm.passCheck = false;

    // Controller Functions
    vm.navigateTo = navigateTo;
    vm.showModal = showModal;
    vm.calculateApprovalAmount = calculateApprovalAmount;
    vm.submitApprovalOverride = submitApprovalOverride;
    vm.validateApprovalAmount = validateApprovalAmount;
    vm.approvalPreSubmit = approvalPreSubmit;
    vm.instantApproval = instantApproval;
    //vm.submitApprovalOverride = submitApprovalOverride;
    vm.validateWorksheets = validateWorksheets;
    vm.declineApproval = declineApproval;
    vm.declineApprovalVerify = declineApprovalVerify;
    vm.submitVerifyAddress = submitVerifyAddress;
    vm.validateAddressVerify = validateAddressVerify;
    vm.validateIncome = validateIncome;
    //AC23434 code changes start
    vm.validateOutstandingBalance = validateOutstandingBalance;
    vm.showAccountResolved = showAccountResolved;
    vm.outstandingReview = {};
    vm.isOutstandingVerified = isOutstandingVerified;
    //AC23434 code changes end
    vm.validateSkipStolen = validateSkipStolen;
    vm.confirmVerfication = confirmVerfication;
    vm.backToVerification = backToVerification;
    vm.validateConditionalApproval = validateConditionalApproval;
    vm.isSkipStolenOverrideVerified = isSkipStolenOverrideVerified;
    /*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
    vm.isSkipStolenOverrideVerifiedDecReason = isSkipStolenOverrideVerifiedDecReason;
    vm.isSkipStolenOverrideVerifiedDecReasonList = isSkipStolenOverrideVerifiedDecReasonList;
    vm.validateConditionalApprovalAssociateReview = validateConditionalApprovalAssociateReview;
    /*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Starts*/
    vm.validateAssocReviewDecline = validateAssocReviewDecline;
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Ends*/
    vm.validateEmployeePass = validateEmployeePass;
    vm.setOverrideAmount = setOverrideAmount;
    vm.init = init;
    $scope.submitEmployeePassReview = verifyAssociatePassReview;
    $scope.submitEmployeePass = verifyAssociatePassOverride;
    $scope.verifyAssociatePassCheck = verifyAssociatePassCheck;
    //start: Feature 8367 code changes
    vm.validateDecline = validateDecline; 
    vm.overrideCheck = false;
    vm.declineCheck = false;
    //end: Feature 8367 code changes

    vm.associatePassFail = false;
    
    vm.error = {
      lease: false,
      noEmpName: false
    };

    // Initialize Controller
    init();

    function init() {
      var findData = { 'firstName': null, 'lastName': null, 'phone': null };
      void 0;
      void 0;
      void 0;
      void 0;
      void 0;

      // RESET ERRORS
      vm.errors.error = false;
      vm.errors.alertClass = '';
      vm.errors.message = '';
      //Feature 8367 code changes
      vm.errors.declineMessage = '';
      vm.error.isDeclineOtherMessage = false;
      vm.approval.declineReason = null;
      vm.approval.otherReason = null;
      var status = SharedDataSvc.manualOverrideData.applicationStatus;

      if(status === 'AssocRvw') {
        $scope.submitEmployeePassReview = verifyAssociatePassReview;
        $scope.submitEmployeePass = verifyAssociatePassReview;
      } else {
        $scope.submitEmployeePassReview = verifyAssociatePassOverride;
        $scope.submitEmployeePass = verifyAssociatePassOverride;
      }
      
      $scope.isSpanishStore = $cookieStore.get('isSpanishStore');

      void 0;

      updateEngagementItemFactory.getData(SharedDataSvc.manualOverrideData.engagementId)
        .then(function (response) {
          $log.log('[manualOverride.init] response', response);
          vm.engagement = response;
          $log.log('[manualOverride.init] engagement', SharedDataSvc.manualOverrideData);
        });
    }

    function navigateTo(target) {
      modalService.dismiss();
      if(target === 'dashboardReload') {
        $route.reload();
        return;
      }
      $location.path(target);
    };

    function showModal(name, windowClass) {
      $log.log('MODAL - show modal', name);
      windowClass = windowClass || '';
      vm.modal = modalService.open({
        templateUrl: '/partials/modal_' + name + '.html',
        backdrop: 'static',
        windowClass: windowClass,
        controller: 'manualOverride',
        controllerAs: 'override',
        // scope: $scope,
        show: true
      });
    }

    function setOverrideAmount() {
      vm.overrideSpec.overrideAmount = vm.approval.requestedAmount;
    }

    function formatApprovalAmount(currentAmount) {
      if(currentAmount) {
        currentAmount = currentAmount.typeof !== 'string' ? String(currentAmount) : currentAmount;
        var amount = currentAmount.indexOf('.') > -1 ? currentAmount.split('.') : currentAmount;
        void 0;
        if(_.isArray(amount)) {
          amount = amount[0] + '.00';
          return amount;
        } else {
          return currentAmount + '.00';
        }
      }
    }

    function calculateApprovalAmount() {
      var requestedAmount = vm.approval.requestedAmount,
          requestedAmount = typeof requestedAmount !== 'number' ? Number(requestedAmount) : requestedAmount,
          currentAmount = typeof vm.currentAmount !== 'number' ? Number(vm.currentAmount) : vm.currentAmount;

      vm.approval.difference = (requestedAmount - currentAmount);

      return vm.approval.difference;
    }

    function validateApprovalAmount() {
    	$log.log('validate amount');
    
      productCategoriesFactory.getData()
        .then(function(response) {
        	
          vm.productCat = response;
          $log.log('CTRL products', vm.productCat);
          
          //var tierObj = getOverrideTier(),
         var max = Number(vm.storeInfo.maximumOverideAmount);

	      //vm.overrideOptions = tierObj.tier;
	      vm.approval.tierIndex = 1;
	
	      // Reset errors
	      vm.errors.error = true;
	      vm.errors.alertClass = '';
	      vm.errors.message = undefined;
	      // Start VAN-2951 -->
	      var pattern = /^\${0,1}[1-9][0-9]{0,7}$/;
	      if(!(pattern.test(vm.approval.requestedAmount))) {
          vm.errors.message = $translate.instant("modal_verifyInitialAmt_error3");
	        //vm.errors.message = 'Please enter a valid Approval Amount';
	      }
	      // End VAN-2951 -->
	      var maxapprovalamount = Number(vm.storeInfo.maximumApprovalAmount);//VAN-2391-Increase in approval amount from DE to $4,000
	      if(Number(vm.approval.requestedAmount) > maxapprovalamount) {
          vm.errors.message = $translate.instant("modal_verifyInitialAmt_error4");
	        //vm.errors.message = 'Request amount exceeds maximum increase limit';
	      } else {
	        //max = Number(vm.storeInfo.maximumOverideAmount);
	
	        calculateApprovalAmount();
	        /*VAN-498 - PR store not allowing to perform multiple overrides- Starts*/ 
	        var currentAmount = typeof vm.currentAmount !== 'number' ? Number(vm.currentAmount) : vm.currentAmount;
	
	        if(currentAmount===0 ||(vm.approval.difference > 0 && vm.approval.difference <= max)) {
	        /*VAN-498 - PR store not allowing to perform multiple overrides- Ends*/ 
	          instantApproval();
            //approvalPreSubmit();
	        } else {
	          approvalOutOfRange(vm.approval.difference, max);
	        }
	      }
          
          
      });
      
    }

    function approvalOutOfRange(difference, max) {
      //var max3 = Number(vm.overrideOptions.increaseMaximum_3),
        var maxTotal = Number(vm.currentAmount) + Number(max);

          void 0;
          void 0;

      if(vm.approval.requestedAmount <= Number(vm.currentAmount)) {
        vm.errors.error = true;
        vm.errors.message = $translate.instant("modal_verifyInitialAmt_error1");
        void 0;
      } 
      if(difference > max) {
        vm.errors.error = true;
        vm.errors.message = $translate.instant("modal_verifyInitialAmt_error2") + maxTotal;
        //vm.errors.message = 'Increase amount cannot be more than $' + maxTotal;
        void 0;
      }
      // if(vm.approval.requestedAmount > maxTotal) {
      //   vm.errors.error = true;
      //   vm.errors.alertClass = 'alert-danger'; // alert-success
      //   vm.errors.message = 'Increase amount is too high';
      //   console.log('Amount requested is too high');
      // }
    }

    function approvalPreSubmit() {
      var max1 = Number(vm.overrideOptions.increaseMaximum_1);
      void 0;
      if(vm.approval.difference <= max1) {
        void 0;
        instantApproval();
      } else {
        void 0;
        handleHigherApproval();
      }
    }

    function handleHigherApproval() {
      $log.log('high amount');
      var max2 = Number(vm.overrideOptions.increaseMaximum_2);

      vm.approval.needsRightSelling = true;

      if(vm.approval.difference > max2) {
        vm.approval.needsLeaseOrder = true;
      } 
      if(manualOverrideFactory.associateReview && manualOverrideFactory.associateReview.customMessage &&
    		  manualOverrideFactory.associateReview.customMessage.indexOf('Verified AccountStatus') > -1){
    	  showModal('employeePass', 'employee-password-modal in');
      }else{
    	  showVerifyModal();
      }
    }

    function setApprovalData() {
      var newAmount = typeof vm.approval.requestedAmount === 'number' ? vm.approval.requestedAmount : Number(vm.approval.requestedAmount);
      void 0;
      vm.overrideSpec.customerId = SharedDataSvc.manualOverrideData.customerId;
      void 0;
      if(vm.overrideSpec.employeeId) {
        delete vm.overrideSpec.employeeId;
      }
      if(vm.overrideSpec.overrideStatus === 'APPROVE') {
        vm.overrideSpec.customMessage = 'Approved by - ' + manualOverrideFactory.approvalModel.employeeName;
      }
       if(vm.overrideSpec.overrideStatus === 'DECLINE') {
        //vm.overrideSpec.customMessage = 'Declined';
//    	   Feature 8367 code changes
    	   if(vm.overrideSpec.overrideAmount){
    		   vm.overrideSpec.overrideAmount=null;
    	   }
    	   vm.overrideSpec.customMessage = vm.approval.declineReason;
    	   if(vm.approval.otherReason){
    		   vm.overrideSpec.customMessage =  vm.overrideSpec.customMessage + ' ' + vm.approval.otherReason;
    	   }
    	   
    	   /*Defect - VAN 326 - Decline reason is not getting updated - Starts*/
    	   if(!vm.overrideSpec.customMessage){
    		   /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Starts*/
    		   vm.overrideSpec.customMessage = 'Declined by - ' + vm.review.employeeName;
    		   /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Ends*/
    	   }
    	   /*Defect - VAN 326 - Decline reason is not getting updated - Ends*/
      }
      void 0;
    }

    function instantApproval() {
      $rootScope.isSubmittedForOverride = true;
      showModal('employeePass');
      void 0;
      // submitApprovalOverride();
    }

    function getOverrideTier() {
      void 0;
      var tier = manualOverrideFactory.getOverrideTier(vm.productCat.overrideOptions, vm.approval.requestedAmount, vm.currentAmount);
      return tier;
    }

    function validateAddressVerify(status) {
      void 0;
      vm.skipCheck = true;
      if(vm.review.confirmDl || vm.review.calledLandlord  || vm.review.other) {   // Have removed  "|| vm.review.confirmUtility" from here as per new changes.
         vm.review.address.noReason = false;
         vm.review.address.nothingSelected = false;

        if(vm.review.other && !vm.review.verifyExplain) {
          vm.review.address.noReason = true;
          vm.errors.error = true;
          vm.errors.alertClass = 'alert-danger';
        } else {
          submitVerifyAddress(status);
        }
      } else {
        vm.review.address.noReason = false;
        vm.review.address.nothingSelected = true;
        vm.errors.error = true;
        vm.errors.alertClass = 'alert-danger';
      }
    }

    function validateIncome(status) {
      vm.skipCheck = true;
      if(vm.review.verifyIncome) {
        void 0;
        submitVerifyIncome(status);
      } else {
        vm.review.income.notCompleted = true;
        vm.errors.error = true;
        vm.errors.alertClass = 'alert-danger';
        void 0;
      }
    }
    
    //AC23434 code changes start
    function validateOutstandingBalance(status) {
        vm.skipCheck = true;
        
        if(!vm.outstandingReview.verifySkipStolen || !vm.outstandingReview.verifyAccountStatus || !vm.outstandingReview.verifyAccountResolved || !vm.outstandingReview.verifyRightSelling || !vm.outstandingReview.verifyException) {
            vm.outstandingReview.notVerified = true;
          }
        
        if(vm.outstandingReview.employeeName && vm.outstandingReview.verifySkipStolen && vm.outstandingReview.verifyAccountStatus && vm.outstandingReview.verifyAccountResolved && vm.outstandingReview.verifyRightSelling && vm.outstandingReview.verifyException) {
          void 0;
          var verified = [
                          [vm.outstandingReview.verifySkipStolen, 'Verified SkipStolen.'],
                          [vm.outstandingReview.verifyAccountStatus, 'Verified AccountStatus.'],
                          [vm.outstandingReview.verifyAccountResolved, 'Verified AccountResolved.'],
                          [vm.outstandingReview.verifyRightSelling, 'Verified RightSelling.'],
                          [vm.outstandingReview.verifyException, 'Verified Exception Lease Order.'],                          
                          [vm.outstandingReview.employeeName, vm.outstandingReview.employeeName]
                        ];
          manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);
          submitVerifyOutstandingBalance(status);
        } 
      }
    
    function submitVerifyOutstandingBalance(status) {
        vm.overrideSpec.overrideStatus = status;
        showModal('employeePassReview', 'employee-password-modal in');
      }
    
    function showAccountResolved() {
    	vm.accountResolvedModal = modalService.openNew({
			templateUrl : 'partials/modal_accountResolved.html',
			backdrop : 'static',
			scope : $scope
		});
    }
    function isOutstandingVerified() {
    	 vm.outstandingReview.notVerified = true;
        if(vm.outstandingReview.verifySkipStolen && vm.outstandingReview.verifyAccountStatus && vm.outstandingReview.verifyAccountResolved && vm.outstandingReview.verifyRightSelling && vm.outstandingReview.verifyException) {
          vm.outstandingReview.notVerified = false;
          return true;
        } 
      }
    //AC23434 code changes end

    function validateEmployeePass() {
      void 0;
      vm.passCheck = true;
      if(!vm.approval.employeePass || !vm.review.employeePass) {
        vm.noEmpPass = true;
        void 0;
      }
    }

    function showVerifyModal() {
      $log.log('verify index for modal: ', vm.approval.tierIndex);
      if(vm.approval.tierIndex === 2 || vm.approval.tierIndex === 1) {
        $log.log('show right selling');
        // SharedDataSvc.manualOverrideData.state = 'right';
        showModal('overrideRightSelling', 'override-verify');
      }

      if(vm.approval.tierIndex === 3) {
        $log.log('show lease order');
        // SharedDataSvc.manualOverrideData.state = 'lease';
        showModal('overrideLeaseOrder', 'override-verify');
      }
    }
//    Feature 8367 code changes starts
    function validateDecline(type){
    	if(vm.approval.declineReason){
    		var declinereason = vm.approval.declineReason;
    		if(declinereason == 'Other'){
    			if(vm.approval.otherReason){
    				validateWorksheets(type);
    			} else {
    				vm.errors.isDeclineOtherMessage = true;
            vm.errors.declineMessage = $translate.instant("modal_decline_error1");
    				//vm.errors.declineMessage = 'Please enter a valid decline reason';
    			}
    		} else {
    			validateWorksheets(type);
    		}
    	} else {
    		vm.errors.declineMessage = $translate.instant("modal_decline_error1");
        //vm.errors.declineMessage = 'Please enter a valid decline reason';
    	}
    }
//    Feature 8367 code changes ends

    function validateWorksheets(type) {
      $log.log('type', type);
      $log.log('tier index', vm.approval.tierIndex);
      $log.log('right selling', vm.approval.rightSelling);
      $log.log('lease order', vm.approval.leaseOrder);
      $log.log('emp name', vm.approval.employeeName);


      if(vm.approval.tierIndex === 3 && type === 'lease') {
        if(vm.approval.rightSelling && vm.approval.employeeName && vm.approval.verifyLease) {
          $log.log('passes lease order validation');
          showModal('employeePass', 'employee-password-modal in');
        } else {
          showWorksheetErrors('lease');
        }
      } else if (vm.approval.tierIndex === 2 && type === 'right' || vm.approval.tierIndex === 1 && type === 'right') {
        if(vm.approval.rightSelling && vm.approval.employeeName) {
          $log.log('passes right selling validation');
          showModal('employeePass', 'employee-password-modal in');
        } else {
          showWorksheetErrors('right');
        }
      } else {
        void 0;
      }

      if(type === 'decline') {
        void 0;
        vm.overrideSpec.overrideStatus = 'DECLINE';
        showModal('overrideDecline');
      }
    }

    function showWorksheetErrors(type) {

      if( $rootScope.selectedLanguage == 'es' ) {
        var messageErrors = ['Se requiere el nombre y la verificación que el formulario esté completo', 
                           'Se requiere el nombre',
                           'Se requiere la verificación que el formulario esté completo'];
      } else {
        var messageErrors = ['Name and verification of form completion is required', 
                           'Name is required',
                           'Verification of form completion is required'];
      }
      

      // vm.errors.error = false;
      // vm.errors.alertClass = ''; 
      // vm.errors.message = undefined;
      void 0;

      if(type === 'right') {
        if(!vm.approval.employeeName && !vm.approval.rightSelling) {
          $log.log('right selling name and form error');
          // vm.error = true;
          // vm.alertClass = 'alert-danger';
          // vm.message = messageErrors[0];

          vm.errors.error = true;
          vm.errors.alertClass = 'alert-danger'; // alert-success
          vm.errors.message = messageErrors[0];
          void 0;
          void 0;
        } else if(!vm.approval.employeeName && vm.approval.rightSelling) {
          $log.log('right selling name error');
          vm.errors.message = messageErrors[1];
          vm.errors.error = true;
          vm.alertClass = 'alert-danger';
        } else {
          $log.log('right selling form error');
          vm.errors.error = true;
          vm.alertClass = 'alert-danger';
          vm.errors.message = messageErrors[2];
        }
      } else {
        void 0;
        vm.error.lease = false;

        if(!vm.approval.employeeName || !vm.approval.rightSelling || !vm.approval.verifyLease) {
          $log.log('name error');
          vm.error.lease = true;
          vm.errors.error = true;
        } 
      }
    }

    function verifyCompletedWorksheets() {
      if(vm.approval.tierIndex === 2) {
        void 0;
        void 0;
        showVerifyModal();
        // submitApprovalOverride();
      } else if (vm.approval.tierIndex === 1) {
        void 0;
        void 0;
        showVerifyModal();
        // submitApprovalOverride();
      } else {
        void 0;
      }
      // submitApprovalOverride();
    }

    function validateSkipStolen() {
      var valid = true;
      vm.skipCheck = true;
      if(!vm.review.employeeName) {
        valid = false;
      }

      if(!vm.review.verifyExplain) {
        valid = false;
      }

      if(!vm.review.verifyEmployment || !vm.review.verifyReference || !vm.review.verifyAddress) {
        valid = false;
        vm.review.notVerified = true;
      }

      if(valid) {
        var verified = [
              [vm.review.verifyEmployment, 'Verified Employment.'],
              [vm.review.verifyReference, 'Verified References.'],
              [vm.review.verifyAddress, 'Verified Address.'],
              [vm.review.employeeName, vm.review.employeeName + ' -'],
              [vm.review.verifyExplain, vm.review.verifyExplain]
            ];

        manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);

        $scope.submitEmployeePass = submitAssociatePass;
        showModal('employeePassSkip', 'employee-password-modal');
      }
    }

    function verifyInfoSubmit() {
      vm.verifyData = {};
      vm.verifyData.engagementId = vm.item.engagementId;
      vm.verifyData.verifyInfoAssociateName = vm.review.employeeName;
      void 0;
      updateEngagementItemFactory.putData(vm.verifyData)
        .then(function(response) {
          void 0;
          modalService.dismiss();
          $route.reload();
        }, function(response) {
          void 0;
          modalService.dismiss();
        });
    }

    /*VAN 253 - VAN- AWP Dashboard View Button Code changes*/
    function confirmVerfication(){
        verifyInfoSubmit();
        showModal('Processing', 'processing-modal');
    }

    function backToVerification(){
      showModal('conditionalApproval', 'associate-review-modal');
    }
    
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Starts*/
    function validateAssocReviewDecline(){
    	 var valid = true;
    	 vm.skipCheck = true;
    	 if(!vm.review.employeeName){
    		 valid = false;
    	 }
    	 if(valid){
    		showModal('declineAssociateReview','decline-associate-review');
    	 }
    }
    /*VAN-464 -VAN - Save reviewer name when coworker manually declines customer - Ends*/

    /*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
    function validateConditionalApprovalAssociateReview(verifiedChkList){
        var valid = true;
        vm.skipCheck = true;
        if(!vm.review.employeeName) {
          valid = false;
        }

        if($scope.showCustAddress){
        	if(!vm.review.verifyAddress){
        		valid = false;
        		vm.review.notVerified = true;
        	}
        }if($scope.showCustReferences){
        	if(!vm.review.verifyReference){
        		valid = false;
        		vm.review.notVerified = true;
        	}
        }

        if(!verifiedChkList){
        	valid = false;
        	vm.review.notVerified = true;
        }
        if(valid) {
          var verified = [
            [vm.review.employeeName, "Approved by"+" - "+vm.review.employeeName]
          ];
          
          manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);
/*//        Comment - Change PR logic similar to US - Start
          if($scope.isSpanishStore) {
            showModal('confirmVerification', 'smallModals');
          } else {*///        Comment - Change PR logic similar to US - End
            $scope.submitEmployeePass = submitAssociatePass;
            showModal('employeePassConditionalApproval', 'employee-password-modal');
//        }
      }
    }
    /*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
    
    function validateConditionalApproval(){
        var valid = true;
        vm.skipCheck = true;
        if(!vm.review.employeeName) {
          valid = false;
        }

        if(!vm.review.verifyExplain && !$scope.isSpanishStore) {
          valid = false;
        }

        if(!vm.review.verifyEmployment || !vm.review.verifyReference || !vm.review.verifyAddress) {
          valid = false;
          vm.review.notVerified = true;
        }

        if(valid) {
          var verified = [
            [vm.review.verifyEmployment, 'Verified Employment.'],
            [vm.review.verifyReference, 'Verified References.'],
            [vm.review.verifyAddress, 'Verified Address.'],
            [vm.review.employeeName, vm.review.employeeName + ' -'],
            [vm.review.verifyExplain, vm.review.verifyExplain]
          ];

          manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);
//        Comment - Change PR logic similar to US - Start
         /* if($scope.isSpanishStore) {
            //verifyInfoSubmit();
            //showModal('Processing', 'processing-modal');
            showModal('confirmVerification', 'smallModals');
          } else {*/
//        Comment - Change PR logic similar to US - End
            $scope.submitEmployeePass = submitAssociatePass;
            showModal('employeePassConditionalApproval', 'employee-password-modal');
//        }
      }
    }

    /*VAN 253 - VAN- AWP Dashboard View Button End*/
    
    /*VAN-249 VAN- Configurable Manual Intervention Response - Starts*/
    function isSkipStolenOverrideVerifiedDecReason() {
      if($scope.showCustAddress){
      	if(vm.review.verifyAddress){
      		vm.review.notVerified = false;
      		if($scope.declineReasonsList.length==0){
      			$scope.verifiedChkList = true;
      		}
            return true;
      	}
      }if($scope.showCustReferences){
        	if(vm.review.verifyReference){
          		vm.review.notVerified = false;
          		if($scope.declineReasonsList.length==0){
          			$scope.verifiedChkList = true;
          		}
                return true;
          	}
          }
    }
    
    var tempList =[];
    function isSkipStolenOverrideVerifiedDecReasonList(verifyReason,declineReasonsList) {
    	$scope.verifiedChkList = false;
    		if(verifyReason.isSelected == true){
    			tempList.push(verifyReason.reason);
    		}if(verifyReason.isSelected == false){
    			tempList.splice(verifyReason.reason,1);
    		}
    		if(declineReasonsList.length == tempList.length){
    			vm.review.notVerified = false;
    			$scope.verifiedChkList = true;
    			return true;
    		}
      }
    /*VAN-249 VAN- Configurable Manual Intervention Response - Ends*/
    
    function isSkipStolenOverrideVerified() {
        if(vm.review.verifyEmployment && vm.review.verifyReference && vm.review.verifyAddress) {
          vm.review.notVerified = false;
          return true;
        } 
    }
    
    function declineApproval() {
      $log.log('decline approval');
      var overrideSpec = _.clone(vm.overrideSpec);
      $log.log('override data', overrideSpec);
      // delete overrideSpec.overrideAmount;
      vm.overrideSpec.customerId = vm.item.customerId;
      vm.overrideSpec.overrideCode = 0;
      vm.overrideSpec.overrideStatus = 'DECLINE';
      vm.overrideSpec.overrideStatus = 'DECLINE';
      vm.item.isCoWorkerDeclined = true;
      vm.item.applicationStatus = 'Declined';
      // vm.item.applicationStatus = 'Declined';
      submitApprovalOverride(overrideSpec);
    }

    function declineApprovalVerify(pass) {
      if(pass) {
        $scope.noEmpPass = false;
        $scope.incorrectPass = false;
        var auth = authentication.storePass;

        if(auth === pass) {
          declineApproval();
        }
        else {
          $scope.incorrectPass = true;
          void 0;
        }
      }
      else {
        $scope.noEmpPass = true;
        void 0;
      }
    }

    function setAddressData() {
      var overrideSpec = _.clone(vm.overrideSpec);
      // delete overrideSpec.overrideAmount;
      overrideSpec.overrideCode = 0;
      overrideSpec.customerId = SharedDataSvc.manualOverrideData.customerId;
      if(vm.review.other) {
        overrideSpec.customMessage = vm.review.summary;
      } else {
        overrideSpec.customMessage = null;
      }
      return overrideSpec;
    }

    function setAddressDataReview() {
      var overrideSpec = _.clone(vm.overrideSpec);
      overrideSpec.overrideCode = 0;
      overrideSpec.customerId = SharedDataSvc.manualOverrideData.customerId;
      overrideSpec.customMessage = manualOverrideFactory.associateReview.customMessage;
      return overrideSpec;
    }

    function submitAssociateReview() {
      void 0;
      var overrideData = setAddressDataReview();
      showModal('Processing', 'processing-modal');
      overrideData.customerId = vm.item.customerId;
      delete overrideData.employeeId;
      if(overrideData.overrideCode === 0) {
        delete overrideData.overrideAmount;
      }
      manualOverrideFactory.submitOverride(overrideData)
        .then(function(response) {
          modalService.dismiss();
          void 0;
          if(vm.overrideSpec.overrideStatus === 'APPROVE') {
            SharedDataSvc.manualOverrideData.applicationStatus = 'Accepted';
            //AC23434 code changes start
            if(manualOverrideFactory.associateReview.customMessage.indexOf('Verified AccountStatus') > -1){
            	showModal('overrideInitial');
            } else { 
            	navigateTo('dashboard');
            }  
            //AC23434 code changes end
          } else {
            SharedDataSvc.manualOverrideData.applicationStatus = 'Declined';
            navigateTo('dashboard');
          }
        }, function(response) {
          modalService.dismiss();
          void 0;
          SharedDataSvc.manualOverrideMessage = response.status_details;
        });
    }

    function buildCustomMessage(array) {
      var message = '';
      _.forEach(array, function(child) {
        if(child[0]) {
          if(message === '') {
            message += child[1];
          } else {
            message += ' ' + child[1];
          }
        }
      });
      void 0;
      return message;
    }

    function submitVerifyAddress(status) {
      var verified = [
        [vm.review.confirmDl, 'Checked Driver\'s license.'],
        [vm.review.calledLandlord, 'Called Landlord.'],
      //  [vm.review.confirmUtility, 'Checked Utility Bill.'],
        [vm.review.other, 'Other -'],
        [vm.review.verifyExplain, vm.review.verifyExplain]
      ];

      manualOverrideFactory.associateReview.customMessage = buildCustomMessage(verified);

      vm.overrideSpec.overrideStatus = status;
      void 0;
      if(SharedDataSvc.manualOverrideData.overallApprovalReason && SharedDataSvc.manualOverrideData.overallApprovalReason.indexOf('Reported income outside of allowable range') > -1) {
        void 0;
        showModal('reviewIncome', 'associate-review-modal');
        vm.errors.alertClass = 'alert-danger';
      } else {
        void 0;
        var overrideData = setAddressData();
        modalService.dismiss();
        showModal('employeePassReview', 'employee-password-modal in');
        // submitAssociateReview(overrideData);
      }
    }

    function submitVerifyIncome(status) {
      vm.overrideSpec.overrideStatus = status;
      showModal('employeePassReview', 'employee-password-modal in');
    }

    function verifyAssociatePassReview(pass) {
      void 0;
      if(pass) {
        $scope.noEmpPass = false;

        var auth = authentication.storePass;

        if(auth === pass) {
          submitAssociateReview();
        } else {
          manualOverrideFactory.associateReview.employeePassSubmitted = true;
          showModal('employeePass');
        }
      } else {
        $scope.noEmpPass = true;
        void 0;
      }
    }

    function verifyAssociatePassCheck(pass) {
      //console.log('associate pass review', pass);
      
      if(pass) {
        $scope.noEmpPass = false;
        var auth = authentication.storePass;

        if(auth === pass) {
          declineApproval();
        } else {
          //manualOverrideFactory.associateReview.employeePassSubmitted = true;
          vm.associatePassFail = true;
          /*VAN - 782 - VAN - On clicking decline button on manual intervention and on entering the wrong password, application is getting approved - Starts*/ 
          manualOverrideFactory.associateReview.employeePassSubmitted = true;
          showModal('employeePassDecline');
          /*showModal('employeePass');*/
          /*VAN - 782 - VAN - On clicking decline button on manual intervention and on entering the wrong password, application is getting approved -  Ends*/ 
          void 0;
        }
      } else {
        $scope.noEmpPass = true;
        void 0;
      }
    }

    function verifyAssociatePassOverride(pass) {
      void 0;
      // manualOverrideFactory.associateReview.employeePassSubmitted = false;
      var auth = authentication.storePass;

      if(auth === pass) {
        void 0;
        if($rootScope.isSubmittedForOverride){
          submitApprovalOverride();
          $rootScope.isSubmittedForOverride = false;
        } else {
          declineApproval();
        }       
        
      } else {
        // showModal('associatePassFail');
        manualOverrideFactory.associateReview.employeePassSubmitted = true;
        showModal('employeePass');
        void 0;
      }
    }

    function submitAssociatePass(pass) {
      var overrideData = setAddressData();

      if(pass) {
        overrideData.employeeId = pass;
        if(overrideData.verifyExplain !== "") {
          if(vm.review.employeeName) {
            overrideData.customMessage = vm.review.verifyExplain + " - " + vm.review.employeeName;
          } else {
            overrideData.customMessage = vm.review.verifyExplain + " - " + vm.approval.employeeName;
          }
        } 
        // submitAssociateReview(overrideData);
      } else {
        void 0;
      }
    }

    function submitApprovalOverride() {
      // console.log('submit approval override');
      showModal('Processing', 'processing-modal');
      setApprovalData();
      $log.log('override spec', vm.overrideSpec);
      vm.overrideSpec.engagementId = vm.engagementId;
      manualOverrideFactory.submitOverride(vm.overrideSpec)
        .then(function(response) {
          modalService.dismiss();
          vm.item.approvalAmount = response.OverrideApprovalAmount;
          if(String(vm.item.approvalAmount).indexOf('.') > -1) {
            var formatAmount = String(vm.item.approvalAmount).split('.');
            if(formatAmount[1].length === 1) {
              vm.item.approvalAmount = formatAmount[0] + '.' + formatAmount[1] + '0';
            }
          }
          if(vm.overrideSpec.overrideCode === 1) {
            showModal('overrideSuccess');
          } else {
            modalService.dismiss();
            $route.reload();
          }
        }, function(response) {
          void 0;
          SharedDataSvc.manualOverrideMessage = response.status_details;
          if(response.data.errorData.status_code === 'ENG-K1004-500'){
            showModal('overrideAlreadyIncreased', 'override-initial-modal');
          } else if(response.data.errorData.status_code === 'ENG-K1005-500' || response.data.errorData.status_code === 'ENG-K1006-500'){
            showModal('overrideAlreadyDeclined', 'override-initial-modal');
          } else {
            showModal('overrideUnsuccess', 'override-initial-modal');
          }
        });
    }
  }

})();
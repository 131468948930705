// OrderSummary
(function() {

	myApp
	.factory('orderEstimatePutFactory', orderEstimatePutFactory);

	function orderEstimatePutFactory(
		$http,
		$q,
		$cookieStore,
		SharedDataSvc,
		datesFactory,
		productFactory,
		API_DOMAIN,
		PROXY_ACTION_URL,
		authentication,
		ServiceAgent
	) {
		var service = {
			compileData: compileData,
			compileQuickQuoteData: compileQuickQuoteData,
			putData: putData,
			getProcessingFee: getProcessingFee,
			checkApprovalAmt : checkApprovalAmt
		}
		return service;

		////////////////

		function compileData(order, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, prodCat, paymentMode, selectedLanguage) {//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
			// var time1 = performance.now();
			var estimateData = {},
				currentEngagement = order.currentEngagement,
				currentEngagementOrder = order.currentEngagementOrder;
				void 0;

			// Hard-coded for Associate Web Portal
			estimateData.vndSalesPerson = "AWP";
			estimateData.employeeName = "AWP";
			estimateData.employeeId = "168045";
			estimateData.orderIdentifier = "";

			// Request Date
			estimateData.requestDte = datesFactory.getCurrentDate();
			// Confirmed order?
			estimateData.confirmed = currentEngagementOrder.orderContent.confirmed;
			// Other amounts
			estimateData.otherAmt = currentEngagementOrder.orderContent.otherFee;
			// VAN Store ID (make sure to add leading 0)
			estimateData.store = currentEngagement.vanStore.storeId;
			// Order identifier (exists if order has been previous saved to engagement)
			estimateData.orderIdentifier = currentEngagementOrder.orderId;
			// Use next possible payment date
			estimateData.nextRenewDte = datesFactory.getFirstPaymentDate(datesFactory.getCurrentDate());
			// Delivery and extended warranty fees, parsed into float; set to 0 if NaN.
			//estimateData.deliveryAmt = parseFloat(currentEngagementOrder.orderContent.deliveryFee) || 0;
			//estimateData.extendService = parseFloat(currentEngagementOrder.orderContent.extendedServiceAmount) || 0;

			// EngagementID
			estimateData.engagementId = order.currentEngagement.engagementId;

			// Inventory List
			estimateData.inventoryList = [];
			void 0;

			_.forEach(order.currentEngagementOrder.orderContent.orderItems, function(item, index) {
				var orderItem = {
					"racBrand" : "ACP", // 'ESV' for extended
					"itemModelNumber" : "", // 'ExtSvc' for extended
					"racModelNumber" : "",
					"depreciableLifeInMonths" : 24,
					"itemOptions" : "",
					"itemOriginalCost" : 0
				};

				orderItem.racBrand = "ACP";
				orderItem.itemModelNumber = "";
				orderItem.racModelNumber = productFactory.getRacModelNumber(prodCat, item, selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
				orderItem.depreciableLifeInMonths = 24;
				orderItem.itemOptions = productFactory.buildItemOptions(item);
				orderItem.itemOriginalCost = SharedDataSvc.formatItemPrice(item.itemPrice);
				void 0;
				estimateData.inventoryList.push(orderItem);
			});
			//PRB0042514 - starts
			if(order.currentEngagementOrder.orderContent.extendedServiceAmount > 0) {
					var extendedWarranty = {
						"racBrand" : "ACP",
						"itemModelNumber" : "ExtSvc",
						"racModelNumber" : "ESVPLAN-1",
						"depreciableLifeInMonths" : 24,
						"racRateCode" : "ESV396",
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(order.currentEngagementOrder.orderContent.extendedServiceAmount)
					};
					estimateData.inventoryList.push(extendedWarranty);
				}
				//PRB0042514 - Ends

			// Delivery and extended warranty fees, parsed into float; set to 0 if NaN.			
			estimateData.feeArray = [
				{
					"feeType": "ESV",
					"feeAmount": parseFloat(currentEngagementOrder.orderContent.extendedServiceAmount) || 0,
					"feeAcrossTerm": false
				},
				{
					"feeType": "Delivery",
					"feeAmount": parseFloat(currentEngagementOrder.orderContent.deliveryFee) || 0,
					"feeAcrossTerm": false
				}
			]

			if(esvdelFeePaymentOptionFlagValue) {
				if(currentEngagementOrder.orderContent.extendedServiceAmount > 0 && currentEngagementOrder.orderContent.deliveryFee <= 0) {
					estimateData.feeArray[0].feeAcrossTerm = true;
				} else if(currentEngagementOrder.orderContent.extendedServiceAmount <= 0 && currentEngagementOrder.orderContent.deliveryFee > 0 && delFeePaymentOptionFlagValue && paymentMode === "monthly") {
					estimateData.feeArray[1].feeAcrossTerm = true;
				} else if(currentEngagementOrder.orderContent.extendedServiceAmount > 0 && currentEngagementOrder.orderContent.deliveryFee > 0) {
					estimateData.feeArray[0].feeAcrossTerm = true;
					if(paymentMode === "monthly" && delFeePaymentOptionFlagValue) {
						estimateData.feeArray[1].feeAcrossTerm = true;
					}
				}
			}

			// customer info
			estimateData.customer = {
				title: "",
				lastName: currentEngagement.customerLastName,
				firstName: currentEngagement.customerFirstName,
				contactPhone1: '999-999-9999'
			}

			estimateData.quoteItem = [];
			for (var i = 0; i < currentEngagementOrder.orderContent.orderItems.length; i++) {
				var item = currentEngagementOrder.orderContent.orderItems[i];
				var newObj = {
					subType: item.subType || '',
					itemId: i.toString(),
					unitType: item.unitType,
					itemDesc: (item.itemDescription.slice(0, 39)).replace('"', ' ').replace('\\', ''),
					qty: item.quantity,
					price: Number(item.itemPrice)
				};
				estimateData.quoteItem.push(newObj);
			}

			void 0;

			// var encodedContent = SharedDataSvc.globalVars.orderEstimatePut;
			// console.log(performance.now() - time1);
			return estimateData;
		}

		function compileQuickQuoteData (storeNo, inputValue, productCategories) {
			void 0;
			if(inputValue.deliveryFee)
			void 0;
			var quickQuoteData = {};
			quickQuoteData.store = storeNo;
			quickQuoteData.quickQuote = true;
			quickQuoteData.inventoryList = [];
			quickQuoteData.feeArray = [];

			var orderItem = {
				"racBrand" : "ACP", // 'ESV' for extended
				"itemModelNumber" : "", // 'ExtSvc' for extended
				"depreciableLifeInMonths" : 24,
				"itemOptions" : "",
				"itemOriginalCost" : inputValue.merchandiseTotal * 100
			};

			if(inputValue.selectedProduct){
				orderItem["racModelNumber"] = getRacModelNumber(productCategories, inputValue.selectedProduct.id, inputValue.merchandiseTotal);
			} else {
				orderItem["racModelNumber"] = getRacModelNumber(productCategories, "LRM", inputValue.merchandiseTotal);
			}

			quickQuoteData.inventoryList.push(orderItem);

			if(inputValue.deliveryFee > 0) {
				var deliveryItem = {
					"feeAmount" : inputValue.deliveryFee,
					"feeAcrossTerm" : false,
					"feeType" : "Delivery"
				};

				quickQuoteData.feeArray.push(deliveryItem);
			}

			if(inputValue.extendedServiceAmount > 0) {
				var extendedService = {
					"racBrand" : "ACP",
					"itemModelNumber" : "ExtSvc",
					"racModelNumber" : "ESVPLAN-1",
					"depreciableLifeInMonths" : 24,
					"itemOriginalCost" : inputValue.extendedServiceAmount * 100
				};

				quickQuoteData.inventoryList.push(extendedService);
			}


			void 0;
			return quickQuoteData;
		}

		function getRacModelNumber (productCategories, htSubTypeVal, itemPrice) {
			var priceBucket = _.where(productCategories, { 'htSubTypeCode': htSubTypeVal })[0].priceBuckets,
				buckets = productFactory.sortBuckets(priceBucket),
				productSubType = htSubTypeVal,
				selectedBucket,
				i = 0,
          		len = buckets.length;

			    while(i < len) {
			      if(buckets[i] < itemPrice) {
			        selectedBucket = buckets[i - 1];
			        break;
			      }
			        i++;
			    }

			    if(!selectedBucket) {
			      selectedBucket = _.last(buckets);
			    }
			    void 0;
			    return String(productSubType) + String(selectedBucket);
		}

		function putData (pData, isDraft, quoteMode) {
			void 0;
			var data = pData || SharedDataSvc.globalVars.orderEstimatePut;
			var deferred = $q.defer();
			var url = PROXY_ACTION_URL;
			var headers = {
				'endTarget': 'rentacentervrto/rest/quoteV2',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'quote-service': SharedDataSvc.quoteService,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,quote-service'
			};

			if(quoteMode) {
				var headers = {
					'endTarget': 'rentacentervrto/rest/quoteV2',
					'sessionToken' : authentication.getCurrentSessionId(),
					'quote-service': 'HT',
					'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
					'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,quote-service'
				};
			}

			data.isDraft = isDraft || false;
			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				data,
				false
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}

		// Get the store processing fee using a fake order
		function getProcessingFee() {
			var deferred = $q.defer();

			var quickQuoteData = {};
			quickQuoteData.store = $cookieStore.get('storeId');
			quickQuoteData.quickQuote = true;
			quickQuoteData.inventoryList = [];
			
			var orderStub = {
				"racBrand" : "ACP", // 'ESV' for extended
				"itemModelNumber" : "", // 'ExtSvc' for extended
				"racModelNumber" : "LRM900",
				"depreciableLifeInMonths" : 24,
				"itemOptions" : "",
				"itemOriginalCost" : 40000
			};
			quickQuoteData.inventoryList.push(orderStub);
			putData(quickQuoteData, false, 'HT')
				.then(
					// Success
					function (response) {
						void 0;
						deferred.resolve(response.processingFee);
					},
					// Error
					function (error) {
						deferred.reject(error);
					}
				);

			return deferred.promise;
		}

		function checkApprovalAmt(pData) {
			void 0;
			var data = pData;
			var deferred = $q.defer();
			var url = PROXY_ACTION_URL;
			var activeStoreId = $cookieStore.get('storeId') ? $cookieStore.get('storeId') : SharedDataSvc.vanStoreId;
			var headers = {
				'RAC-KIOSK-LOCATION-ID' : activeStoreId,
				'endTarget': 'rentacentervrto/rest/checkApprovalAmount',
				'sessionToken' : authentication.getCurrentSessionId(),
				'Public-Key': SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey,
				'quote-service': SharedDataSvc.quoteService,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,RAC-CORRELATION-ID,quote-service'
			};

			ServiceAgent.httpCall(
				url,
				'POST',
				headers,
				data,
				false
		    ).then(function (response) {
		    	void 0;
		    	deferred.resolve(response);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}
	}
})();
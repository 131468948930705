(function() {
  'use strict';

  angular
    .module('myApp')
    .factory('manualOverrideFactory', manualOverrideFactory);


  function manualOverrideFactory ($log, $http, $q, SharedDataSvc, API_DOMAIN, productCategoriesFactory, PROXY_ACTION_URL, ServiceAgent, authentication) {
    var approvalModel = {
      requestedAmount: null,
      employeeName: null,
      rightSelling: false,
      verifyIncome: false,
      verifyEmployment: false,
      leaseOrder: false,
      decline: false,
      accepted: true,
      difference: null,
      outOfRange: false,
      tierIndex: undefined,
      employeePass: undefined,
      declineReason: null,
      otherReason: null
    };

    var associateReview = {
      checkedDl: false,
      confirmDl: false,
      calledLandlord: false,
      confirmUtility: false,
      other: false,
      verifyExplain: "",
      verifyIncome: false,
      employeeName: null,
      employeePass: undefined,
      employeePassSubmitted: false,
      verifyEmployment: null,
      verifyReference: null,
      verifyAddress: null,
      notVerified: false,
      summary: null,
      customMessage: undefined,
      address: {
        noReason: false,
        nothingSelected: false
      },
      income: {
        notCompleted: false
      }
    };

    var errorModel = {
      message: undefined,
      error: false,
      alertClass: ''
    };

    return {
      approvalModel: approvalModel,
      errorModel: errorModel,
      associateReview: associateReview,
      isApplicationApproved: isApplicationApproved,
      isStoreManned: isStoreManned,
      getManualOverrideData: getManualOverrideData,
      getProductCategories: getProductCategories,
      submitOverride: submitOverride,
      getOverrideTier: getOverrideTier
    };

    function isApplicationApproved(status) {

    }

    function isStoreManned(store) {

    }

    function getManualOverrideData() {
      return SharedDataSvc.manualOverrideData;
    }

    function getProductCategories() {
      return productCategoriesFactory.getData()
        .then(function(response) {
          $log.log('[manualOverrideFactory.getProductCategories] ProductCat json', response);
          return response;
        }, function(response) {
          $log.log('[manualOverrideFactory.getProductCategories] Unable to get productCategories');
          return response.data;
        });
    }

    function getOverrideTier(optionTiers, requestedAmount, originalAmount) {
      var selectedTier,
          tierIndex,
          max,
          i = 0,
          len = optionTiers.length,
          difference = requestedAmount - originalAmount;

      void 0;
      void 0;

      if(requestedAmount > Number(optionTiers[0].approvalMaximum) && requestedAmount < Number(optionTiers[1].approvalMaximum)) {
        selectedTier = optionTiers[1];
      } else if(requestedAmount < Number(optionTiers[0].approvalMaximum)) {
        selectedTier = optionTiers[0];
      } else {
        selectedTier = optionTiers[2];
      }

      while(i < len) {
        tierIndex = i;
        max = typeof optionTiers[i].approvalMaximum === 'number' ? optionTiers[i].approvalMaximum : Number(optionTiers[i].approvalMaximum);
        // if(difference > optionTiers[i].increaseMaximum_2) {
        //   tierIndex = 2;
        //   break;
        // }
        // if(requestedAmount <= max) {
        //   // selectedTier = optionTiers[i];
        //   break;
        // } 
        if(difference > Number(selectedTier.increaseMaximum_1) && difference <= Number(selectedTier.increaseMaximum_2)) {
          tierIndex = 2;
          break;
        } else if(difference < Number(selectedTier.increaseMaximum_1)) {
          tierIndex = 0;
          break;
        } else {
          tierIndex = 3;
          break;
        }

        i++;
      }

      // _.forEach(optionTiers, function(tier, i){
      //   tierIndex = i;
      //   max = typeof tier.approvalMaximum === 'number' ? tier.approvalMaximum : Number(tier.approvalMaximum);
      //   if(requestedAmount <= max) {
      //     selectedTier = tier;
      //     return false;
      //   }
      // });
      void 0;
      return { 'tier': selectedTier, 'tierIndex': tierIndex };
    }

    function submitOverride (overrideData) {
      void 0;
      var deferred = $q.defer();
      var headers = {
        'endTarget': 'rentacentervrto/rest/kioskOverride',
        'sessionToken' : authentication.getCurrentSessionId()
      };
      ServiceAgent.httpCall(
        PROXY_ACTION_URL, 
        'POST', 
        headers, 
        overrideData, 
        false
        ).then(function (response) {
          void 0;
          deferred.resolve(response);
        },
        function (response) {
          void 0;
            deferred.reject(response);
        });

        return deferred.promise;
    }

  }
})();
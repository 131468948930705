(function() {
	'use strict';

	angular
		.module('myApp')
		.factory('customerDataFactory', customerDataFactory);

	/* @ngInject */
	function customerDataFactory(
		$q,
		$http,
		SharedDataSvc,
		API_DOMAIN,
		PROXY_ACTION_URL,
		authentication,
		ServiceAgent
	) {
		var service = {
			findByEngagementId: findByEngagementId
		};
		return service;

		////////////////

		function findByEngagementId (engagementId, useHT) {
			useHT = useHT || false;
			void 0;
			var deferred = $q.defer();
			if (!engagementId) {
				void 0;
				deferred.reject(null);
				return deferred.promise;
			}
			var url = PROXY_ACTION_URL;
			var params = '&disableHT='+!useHT+'&engagementId=' + engagementId;
			var encryptParams = SharedDataSvc.encrypt(params, SharedDataSvc.globalVars.storeSetup.serverPublicRSAKey); //ServiceAgent.encryptData(params);
			var headers = {
				'endTarget': 'rentacentervrto/rest/customer/find',
				'sessionToken' : authentication.getCurrentSessionId(),
				'params': encryptParams,
				'Access-Control-Allow-Origin':'endTarget,sessionToken,Encrypt-Mode,RAC-KIOSK-LOCATION-ID,Public-Key,params,RAC-CORRELATION-ID' //Story 26289 code changes
			};

			ServiceAgent.httpCall(
				url, 
				'POST', 
				headers, 
				null,
				true
		    ).then(function (response) {
		    	void 0;
		    	var successData = (typeof response !== "object") ? JSON.parse(response) : response;
		    	deferred.resolve(successData);
		    },
		    function (response) {
		    	void 0;
		      	deferred.reject(response);
		    });

		    return deferred.promise;
		}
	}
})();
(function() {
	'use strict';

	angular
	.module('app.order')
	.directive('customAttributes', customAttributes);

	/* @ngInject */
	/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
	function customAttributes ($rootScope) {
		/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
		/*
		Usage: Use product data to build out attribute entry section that returns data properly formatted for sending to services
		Creates: Various types of input fields
		*/
		var directive = {
			require: 'ngModel',
			restrict: 'A',
			scope: {
				attributeData: '=customAttributes'
			},
			templateUrl: 'partials/directive_customAttributes.html',
			link: link
		};
		return directive;

		function link(scope, element, attrs, ngModelController) {
			var normalizedTypes = {
				'integer': 'number',
				'quantity': 'number',
				'checkBox': 'checkbox',
				'text': 'text',
				'radio': 'radio'
			};
			var localAttributesModel;

			/* Take the attribute data and build out the attributes section piece by piece
			*/
			function prepareAttributes(attributeData) {
				// initialize by clearing existing data
				$(element).empty();
				localAttributesModel = [];
				updateAttributesModel()

				if (typeof attributeData === 'undefined') return;
				// Loop through attribute groups
				for(var i = 0; i < attributeData.length; i++) {
					var attributeGroup = attributeData[i];
					var $groupDiv = $('<div>', {
						class: 'form-group attribute-group'
					}).append('<hr/>');
					// Loop through individual attributes
					for (var ii = 0; ii < attributeGroup.customAttributes.length; ii++) {
						var attribute = attributeGroup.customAttributes[ii],
							attributeType = normalizedTypes[attribute.fieldType],
							attributeId = attribute.htCode ? 'att-'+attribute.htCode : '';
						var $label, $input, $inputDiv;
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
						var data = $rootScope.selectedLanguage=="es"?attribute.labelEs:attribute.labelEn;
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
						$label = $('<label>', {
							class: 'attribute-'+attributeType+'-label',
							for: attributeId
						})
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
						.text(data);
						/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
						$input = buildInput({
								type: attributeType,
								name: data.replace(/\r?\n|\r/g,'')
						});

						$inputDiv = $('<div>', {
							class: 'attribute-field'
						})
						.append($label);

						if (attribute.customAttributeOptions) {
							// Set form validity since these options are always required, for now
							ngModelController.$setValidity('required', false);
							// Loop through individual attribute options (typically a radio button)
							for(var iii = 0; iii < attribute.customAttributeOptions.length; iii++) {
								var attributeOption = attribute.customAttributeOptions[iii];
								var $radioOptionDiv, $optionInput, $optionLabel;

								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
								var dataOption = $rootScope.selectedLanguage=="es"? attributeOption.labelEs:attributeOption.labelEn;
								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
								
								$radioOptionDiv = $('<div>', {
									class: 'attribute-radio-option'
								});

								$optionInput = $input.clone(true).attr({ // clone with event attached
									id: 'attr-'+attributeOption.htCode,
									value: dataOption
								});

								$optionLabel = $label.clone().attr({
									for: 'attr-'+attributeOption.htCode,
									class: 'attribute-radio-option-label'
								})
								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
								.text(dataOption);
								/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
								// Add label and input to it's own radio option div
								$radioOptionDiv.append($optionInput, $optionLabel);
								$inputDiv.append($radioOptionDiv);
							}
						} else {
							$input.attr({
								id: attributeId,
								placeholder: attribute.placeHolderEn
							});
							// AC-11985, AC-13473 Code changes Start
							if($input.attr('type')=="number"){
								$input.attr({
									"min": "0",
									"ng-pattern": "0+\.[0-9]*[1-9][0-9]*$",
									"onkeypress": "return event.charCode >=48 && event.charCode <= 57;"
									});
							// AC-11985, AC-13473 Codes changes end
							}
							$inputDiv.append($input);
						}

						// Add each item to the local data model
						localAttributesModel.push(createAttributeObject(attribute));
						// Start adding the items to each other
						$groupDiv.append($inputDiv);
						$(element).append($groupDiv);
					}
				}
				updateAttributesModel();
			}

			/* Build the attribute object that will be added to model array
			*/
			function createAttributeObject(attribute) {
				var attributeType,
					attributeValue;
				
				/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Starts*/
				var attributeLan = $rootScope.selectedLanguage=="es"?attribute.labelEs:attribute.labelEn;
				/*VAN 492 - PR-AWP: Categories in Quick quote and order page showing in English even though language selected is Spanish - Ends*/
				
				switch(normalizedTypes[attribute.fieldType]) {
					case 'checkbox':
						attributeValue = "0";
						break;
					default:
						attributeValue = '';
						break;
				}
				return {
					customAttribute: attributeLan,
					value: attributeValue
				}
			}

			/* Update the named attribute in the local data model
			*/
			function updateAttributeValue(name, value) {
				var attributeIndex = _.findIndex(localAttributesModel, {customAttribute: name});
				localAttributesModel[attributeIndex].value = value.toString();
				updateAttributesModel();
			}

			/* Give us a new input element
			*/
			function buildInput(inputParams) {
				var $input = $('<input>', inputParams)
				.on('change', handleAttributeChange);
				return $input;
			}

			/*  Special event handling for attribute value changes
			*/
			function handleAttributeChange(event) {
				var target = event.target,
						targetValue = target.value;

				switch(target.type) {
					case 'checkbox':
						targetValue = $(event.target).prop('checked') ? "1" : "0";
						break;
					case 'radio':
						ngModelController.$setValidity('required', true);
						break;
				}
				updateAttributeValue(target.name, targetValue);
			}


			function updateAttributesModel() {
				var newData = localAttributesModel.length > 0 ? localAttributesModel : undefined;
				//AC 15028 code changes starts -- only if new data has value update it to view value
				if(newData){
					ngModelController.$setViewValue(newData);
				} else {
					ngModelController.$setValidity('required', true);
				}
				//AC 15028 code changes ends	
			}

			scope.$watch('attributeData', prepareAttributes);
		}
	}
})();
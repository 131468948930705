'use strict';

myApp
.directive('globalMessage', ['SharedDataSvc', function(SharedDataSvc) {
  return {
    restrict: 'E',
    //controller: '',
    replace: true,
    //transclude: true,
    link: function (scope, element, attrs) {
      scope.message = SharedDataSvc.globalmessage;
      scope.cssClass = 'alert-warning';
      scope.showthis = SharedDataSvc.globalmessageShow;
    },
    template: '<div class="alert {{cssClass}}" ng-show="{{showthis}}">{{message}}</div>' //  ng-show="globalmessageShow"
  };
}]);
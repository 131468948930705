// dashboard
myApp.factory('getInitialDashboard', getInitialDashboard);

function getInitialDashboard(
	$http,
	$q,
	$cookieStore,
	SharedDataSvc,
	API_DOMAIN,
	ServiceAgent,
	authentication,
	PROXY_ACTION_URL
) {
	var activeStoreId;
	return {
		getData: getData,
		sendAgreements: sendAgreements
	}

	//https://rac-van-svcs-dd-dev.cloudhub.io/rentacentervrto/rest/email/resendAgreements?customerId=97322&engagementId=96881&emailSendTo=STORE
	function sendAgreements (customerId, engagementId, toCustomer, toStore, custEmail) {
		void 0;

		var deferred = $q.defer();
		var sendTo = toCustomer && toStore ? 'BOTH' : (toCustomer ? 'CUSTOMER' : 'STORE');
		var headers = {
			'endTarget': 'rentacentervrto/rest/email/resendAgreements',
			'sessionToken' : authentication.getCurrentSessionId()
		};
		var url = PROXY_ACTION_URL + '?customerId=' + customerId + '&engagementId=' + engagementId + '&emailSendTo=' + sendTo;
		if(custEmail){
			url = url + '&custEmail=' + custEmail;
		}
		ServiceAgent.httpCall(
			url, 
			'POST', 
			headers, 
			null, 
			false
	    ).then(function (response) {
	    	void 0;
	    	deferred.resolve(response);
	    },
	    function (response) {
	    	void 0;
	      	deferred.reject(response);
	    });

	    return deferred.promise;
	}

	function getData() {
		var deferred = $q.defer();
	    var doEncrypt = true;
		if ($cookieStore.get('storeId')) {
			activeStoreId = $cookieStore.get('storeId');
		} else {
			activeStoreId = SharedDataSvc.vanStoreId;
		}

		var headers = {
			'endTarget': 'rentacentervrto/rest/db/retrieveDashboard',
			'sessionToken' : authentication.getCurrentSessionId()
		};
		var url = PROXY_ACTION_URL + '?vanStoreId=' + activeStoreId;
		void 0;
	    ServiceAgent.httpCall(
	      url, 
	      'POST', 
	      headers, 
	      null, 
	      doEncrypt
	    ).then(function (response) {
	    	if (!doEncrypt) {
	    		deferred.resolve(response);
	    	} else {
	    		var successData = JSON.parse(response);
				deferred.resolve(successData);
	    	}
	    },
	    function (response) {
	      deferred.reject(response);
	    });

	    return deferred.promise;
	}
}
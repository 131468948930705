'use strict';

// for obfuscation on blur
myApp
.directive('prefixer', function() {
	return {
		restrict: 'A',
		link: function(scope, element, attrs) {
			element
				.on('focus', function(e) {
					var n = element.val();
					if (n.indexOf("$") != 0) {
						n = n.replace(/[$a-zA-Z]/g, "");
						n = "$" + n;
						scope.$apply(function() {
							element.val(n);
						});
					}
				})
				.on('keydown', function(e) {
					var key = e.keyCode;
					// // if ((key > 47 && key < 58) || (key > 95 && key < 106) || key === 190 || key === 110) {
					//  return true;
					// } else {
					//  return false;
					// }
				})
				.on('keyup', function(e) {
					var n = element.val();
					if (isNaN(n) && n.indexOf("$") == 0) {
						n = n.replace(/[a-zA-Z]/g, "");
						element.val(n);
					} else {
						if (n.indexOf("$") != 0) {
							n = n.replace(/[$a-zA-Z]/g, "");
							n = "$" + n;
						}
						scope.$apply(function() {
							element.val(n);
						});
					}
				});
		}
	}
});
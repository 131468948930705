'use strict';

// add attr 'focusme' to any element to focus on load
myApp
.directive('tooltip', function() {
  return {
    template: '<span class="glyphicon glyphicon-info-sign" data-original-title="{{title}}" data-trigger="hover" data-container="body" data-toggle="popover" data-placement="bottom" data-content="{{created}}"></span>',
    restrict: 'A',
    scope: {
      created:"=",
      title:"@heading"
    },
    link: function(scope, element, attrs) {
      $(function () {
        $('[data-toggle="popover"]').popover();
      });
    }
  }
});
angular
    .module('myApp')
    .directive('ngPrint', printDirective);

function printDirective() {
    var directive = {
        link: link,
        restrict: 'A'
    };

    var printSection = document.getElementById('printSection');
    // if there is no printing section, create one
    if (!printSection) {
        printSection = document.createElement('div');
        printSection.id = 'printSection';
        document.body.appendChild(printSection);
    }

    return directive;

    function link(scope, element, attrs) {
        var elemToPrintButton;

        element.on('click', function () {
            elemToPrintButton = document.getElementById(attrs.id);
            var elemToPrint = $(elemToPrintButton).parents(".modal-content").find("iframe");
            if (elemToPrint) {
                printElement(elemToPrint, elemToPrintButton);
                $("body").addClass("printView");
            }
        });
    }
    function printElement(elem, elemButton) {
        // clones the element you want to print
        printSection.innerHTML = '';
        $(elem).clone().appendTo(printSection);
        var iframeName = elem.attr('name');
        window.frames[iframeName].focus();
        window.frames[iframeName].print();
        printSection.innerHTML = '';
        $("body").removeClass("printView");
    }
}
myApp.factory('updateEngagementItemFactory', function ($http, $q, SharedDataSvc, API_DOMAIN, PROXY_ACTION_URL, authentication, ServiceAgent) {
	return {
		getData : function (engagementId) {
			void 0;
			SharedDataSvc.globalVars.engagementId =engagementId;// AC 27054 Code changes
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/db/customerEngagement';
			headers.sessionToken = authentication.getCurrentSessionId();
			ServiceAgent.httpCall(
				PROXY_ACTION_URL + '?engagementId=' + engagementId,
				'POST',
				headers,
				null,
				false
			).then(
				function (response) {
					void 0;
					deferred.resolve(response);
				},
				function (response) {
					void 0;
					deferred.reject(response);
				}
			);
			return deferred.promise;
		},
		putData : function (dData) {
			void 0;
			var deferred = $q.defer();
			var headers = SharedDataSvc.commonHeadersJSON;
			headers.endTarget = 'rentacentervrto/rest/db/customerEngagement-PUT';
			headers.sessionToken = authentication.getCurrentSessionId();
			delete dData.status;

			ServiceAgent.httpCall(
				PROXY_ACTION_URL,
				'POST',
				headers,
				dData,
				false
			).then(
				function (response) {
					void 0;
					deferred.resolve(response);
				},
				function (response) {
					void 0;
					deferred.reject(response);
				}
			);
			return deferred.promise;
		}
	}
});